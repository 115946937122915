const routes = [
  {
    path: 'traceability',
    name: 'traceabilityTaco',
    component: () => import(/* webpackChunkName: "traceability-taco" */ './views/Traceability.vue'),
    meta: {
      permissionRequired: true,
    },
  },
];
export default routes;
