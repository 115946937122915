const routes = [
  {
    path: 'production-planning-camso',
    component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'productionPlanningCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/ProductionPlanning.vue'),
      },
      {
        path: 'settings/:id?',
        name: 'productionPlanSettingsCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/ProductionPlanSettings.vue'),
      },
      {
        path: 'plan',
        name: 'addProductionPlanCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/AddProductionPlan.vue'),
      },
      {
        path: 'reorder',
        name: 'reorderPlansCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/ReorderProductionPlans.vue'),
      },
      {
        path: 'plan/:id',
        name: 'productionPlanDetailsCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/ProductionPlanDetails.vue'),
        props: true,
      },
      {
        path: 'plan/:id/edit',
        name: 'editProductionPlanCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/EditProductionPlan.vue'),
        props: true,
      },
      {
        path: 'plan/:id/duplicate',
        name: 'duplicateProductionPlanCamso',
        component: () => import(/* webpackChunkName: "production-planning-camso" */ './views/DuplicateProductionPlan.vue'),
        props: true,
      },
    ],
  },
];

export default routes;
