import { set } from '@shopworx/services/util/store.helper';
// import { sortArray } from '@shopworx/services/util/sort.service';

export default ({
  namespaced: true,
  state: {
    loading: false,
    checklists: {},
    lastRefreshedAt: null,
    productDetails: null,
    allChecklist: null,
    savedChecklist: [],
  },
  mutations: {
    setLoading: set('loading'),
    setChecklists: set('checklists'),
    setLastRefreshedAt: set('lastRefreshedAt'),
    setProductDetails: set('productDetails'),
    setAllChecklist: set('allChecklist'),
    setSavedChecklist: set('savedChecklist'),
  },
  actions: {
    fetchSavedChecklist: async ({
      dispatch,
      commit,
    }, productCode) => {
      commit('setLoading', true);
      commit('setSavedChecklist', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'checklistdata',
          query: `?query=productcode=="${productCode}"&sortquery=createdTimestamp==1`,
        },
        { root: true },
      );
      if (records && records.length) {
        const savedChecklist = records;
        commit('setSavedChecklist', savedChecklist);
      }
      commit('setLoading', false);
    },
  },
  getters: {
    checklistData: ({
      savedChecklist,
    }) => {
      let checklist = null;
      if (savedChecklist.length) {
        checklist = savedChecklist;
      }
      if (checklist && checklist.length) {
        checklist = checklist.reduce((acc, curr) => {
          const { machinename } = curr;
          acc[machinename] = acc[machinename] || {};
          acc[machinename].data = acc[machinename].data || [];
          acc[machinename].productcreatedat = curr.productcreatedat;
          acc[machinename].machinename = curr.machinename;
          acc[machinename].partname = curr.partname;
          acc[machinename].operatorname = curr.operatorname;
          acc[machinename].operatorcode = curr.operatorcode;
          acc[machinename].data.push(curr);
          return acc;
        }, {});
        /* eslint no-restricted-syntax:
        ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
        for (const key of Object.keys(checklist)) {
          // checklist[key].data = sortArray(checklist[key].data, 'parameter');
          checklist[key].data = checklist[key].data.reduce((acc, curr) => {
            let { group } = curr;
            if (!group) {
              group = 'GENERAL';
            }
            acc[group] = acc[group] || [];
            if (curr.value === undefined) {
              if (curr.inputtype.toLowerCase() === 'radio') {
                curr.value = 'OK';
              } else {
                curr.value = '';
              }
            } else {
              if (curr.value === 'false') {
                curr.value = 'NOT OK';
              }
              if (curr.value === 'true') {
                curr.value = 'OK';
              }
            }
            acc[group].push(curr);
            return acc;
          }, {});
        }
      }
      return checklist;
    },
    partDetails: ({
      savedChecklist,
    }) => {
      let partDetails = {};
      let firstRow = [];
      if (savedChecklist.length) {
        [firstRow] = savedChecklist;
        const {
          model, variant, partname, customer,
        } = firstRow;
        partDetails = {
          model,
          variant,
          partname,
          customer,
        };
      }
      return partDetails;
    },
  },
});
