const routes = [
  {
    path: 'maintenance-plan',
    component: () => import(/* webpackChunkName: "maintenance-planning" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'maintenancePlan',
        component: () => import(/* webpackChunkName: "maintenance-planning" */ './views/MaintenancePlanning.vue'),
      },
      {
        path: 'plan',
        name: 'addMaintenancePlan',
        component: () => import(/* webpackChunkName: "maintenance-planning" */ './views/AddMaintenancePlan.vue'),
      },
      {
        path: 'plan/:id',
        name: 'maintenancePlanDetails',
        component: () => import(/* webpackChunkName: "maintenance-planning" */ './views/MaintenancePlanDetails.vue'),
        props: true,
      },
      {
        path: 'plan/:id/edit',
        name: 'editMaintenancePlan',
        component: () => import(/* webpackChunkName: "maintenance-planning" */ './views/EditMaintenancePlan.vue'),
        props: true,
      },
      {
        path: 'plan/:id/duplicate',
        name: 'duplicateMaintenancePlan',
        component: () => import(/* webpackChunkName: "maintenance-planning" */ './views/DuplicateMaintenancePlan.vue'),
        props: true,
      },
    ],
  },
];

export default routes;
