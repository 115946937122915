import {
  set, toggle, reactiveSetArray, reactiveRemoveArray,
} from '@shopworx/services/util/store.helper';
import { sortArray } from '@shopworx/services/util/sort.service';
import HourService from '@shopworx/services/api/hour.service';

export default ({
  namespaced: true,
  state: {
    calendarRef: null,
    calendarFocus: '',
    today: new Date().toISOString().substr(0, 10),
    masterData: [],
    drawer: false,
    view: 'default',
    assets: [],
    molds: [],
    machines: [],
    parts: [],
    shifts: [],
    planningList: [],
    reorderPlanList: [],
    selectedPlans: [],
    loading: false,
    error: false,
    planningCount: 0,
    toggleSelection: false,
    lastRefreshedAt: null,
    lastRefreshedReorder: null,
    selectedPlan: [],
    assetList: [{
      text: 'Carcass',
      value: 6,
    }, {
      text: 'Curing',
      value: 8,
    }],
  },
  mutations: {
    setCalendarRef: set('calendarRef'),
    setCalendarFocus: set('calendarFocus'),
    setMasterData: set('masterData'),
    setToggleSelection: set('toggleSelection'),
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    setView: set('view'),
    setAssets: set('assets'),
    setMolds: set('molds'),
    setMachines: set('machines'),
    setSelectedPlans: set('selectedPlans'),
    setParts: set('parts'),
    setShifts: set('shifts'),
    setLoading: set('loading'),
    setError: set('error'),
    setPlanningCount: set('planningCount'),
    setPlanningList: set('planningList'),
    setPlan: reactiveSetArray('planningList'),
    setMachine: reactiveSetArray('machines'),
    setNotStartedPlan: reactiveSetArray('reorderPlanList'),
    setPlanView: reactiveSetArray('selectedPlan'),
    removePlan: reactiveRemoveArray('planningList'),
    removeNotStartedPlan: reactiveRemoveArray('reorderPlanList'),
    setReorderPlanList: set('reorderPlanList'),
    setLastRefreshedAt: set('lastRefreshedAt'),
    setLastRefreshedReorder: set('lastRefreshedReorder'),
    setSelectedPlan: set('selectedPlan'),
  },
  actions: {
    fetchAssets: async ({ commit, dispatch }) => {
      const assets = await dispatch(
        'industry/getAssets',
        null,
        { root: true },
      );
      if (assets && assets.length) {
        commit('setAssets', assets);
        return true;
      }
      return false;
    },

    fetchMolds: async ({ commit, dispatch }, assetId = null) => {
      let query = '';
      if (assetId) {
        query = `?query=assetid==${assetId}`;
      }
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'mold',
          query,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMolds', records);
        return true;
      }
      return false;
    },

    fetchMachines: async ({ commit, dispatch }, assetId = null) => {
      let query = '';
      if (assetId) {
        query = `?query=assetid==${assetId}`;
      }
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'machine',
          query,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMachines', records);
        return true;
      }
      return false;
    },

    fetchParts: async ({ commit, dispatch }, assetId = null) => {
      let query = '';
      if (assetId) {
        query = `?query=assetid==${assetId}`;
      }
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'part',
          query,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setParts', records);
        return records;
      }
      return false;
    },

    fetchShifts: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'businesshours',
          query: '?sortquery=sortindex==1',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setShifts', records);
        return true;
      }
      return false;
    },

    fetchPlanningList: async ({
      commit, dispatch, getters, rootGetters,
    }) => {
      const filters = rootGetters['webApp/filters'];
      let [min, max] = filters.date.value;
      [min, max] = getters.getDateRange([min, max]);
      commit('setLoading', true);
      commit('setPlanningList', []);
      commit('setError', false);
      const completedOrAbortedPlans = `(actualstart<${max}%26%26actualend>${min})`;
      const inProgressPlans = `(status=="inProgress"%26%26actualstart<${max})`;
      const newPlans = `(status=="notStarted"%26%26scheduledstart>${min}%26%26scheduledstart<${max})`;
      const or = '%7C%7C';
      const data = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'planning',
          query: `?query=${completedOrAbortedPlans}${or}${inProgressPlans}${or}${newPlans}`,
        },
        { root: true },
      );
      if (data && data.results) {
        const plans = data.results.map((plan) => {
          const equipmentname = plan.moldname || plan.toolname;
          return {
            ...plan,
            equipmentname,
          };
        });
        commit('setPlanningList', plans);
        commit('setPlanningCount', data.totalCount);
        commit('setError', false);
        commit('setLastRefreshedAt', new Date().toLocaleTimeString('en-GB'));
      } else {
        commit('setPlanningList', []);
        commit('setPlanningCount', 0);
        commit('setError', true);
      }
      commit('setLoading', false);
    },

    fetchReorderPlanList: async ({ commit, dispatch }) => {
      commit('setLoading', true);
      commit('setReorderPlanList', []);
      commit('setError', false);
      const data = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'planning',
          query: '?query=status=="notStarted"%26%26assetid!=7&sortquery=sortindex==1',
        },
        { root: true },
      );
      if (data && data.results) {
        const plans = data.results.map((plan) => {
          const equipmentname = plan.moldname || plan.toolname;
          return {
            ...plan,
            equipmentname,
          };
        });
        commit('setReorderPlanList', plans);
        commit('setError', false);
        commit('setLastRefreshedReorder', new Date().toLocaleTimeString('en-GB'));
      } else {
        commit('setReorderPlanList', []);
        commit('setError', true);
      }
      commit('setLoading', false);
    },

    fetchPartMatrix: async ({ dispatch }, { partnumber }) => {
      const payload = {
        elementName: 'partmatrix',
        query: `?query=partnumber=="${encodeURIComponent(partnumber)}"`,
      };
      const partMatrixRecords = await dispatch(
        'element/getRecords',
        payload,
        { root: true },
      );
      if (partMatrixRecords && partMatrixRecords.length) {
        return partMatrixRecords.map((matrix) => ({
          ...matrix,
          equipmentname: matrix.moldnumber,
        }));
      }
      return [];
    },

    getScheduledEnd: async (_, { start, duration }) => {
      const { data } = await HourService.getPlanEndTime(start, duration);
      if (data && data.results) {
        return data.results;
      }
      return start + duration;
    },

    fetchLastPlan: async ({ dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'planning',
          query: '?sortquery=sortindex==-1&pagenumber=1&pagesize=1',
        },
        { root: true },
      );
      if (records && records.length === 1) {
        return records[0];
      }
      return null;
    },

    fetchPlan: async ({ commit, dispatch }, planId) => {
      commit('setSelectedPlan', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'planning',
          query: `?query=planid=="${planId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSelectedPlan', records);
        return true;
      }
      commit('setSelectedPlan', []);
      return false;
    },

    createPlan: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'planning',
          payload,
        },
        { root: true },
      );
      return created;
    },

    createPlans: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postBulkRecords',
        {
          elementName: 'planning',
          payload,
        },
        { root: true },
      );
      return created;
    },

    updatePlanByPlanId: async ({
      state,
      commit,
      dispatch,
    }, { planId, payload, listType }) => {
      const updated = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'planning',
          queryParam: `?query=planid=="${planId}"`,
          payload,
        },
        { root: true },
      );
      if (listType === 'reorder') {
        const { reorderPlanList } = state;
        for (let i = 0; i < reorderPlanList.length; i += 1) {
          if (reorderPlanList[i].planid === planId) {
            commit('setNotStartedPlan', {
              index: i,
              payload: {
                ...reorderPlanList[i],
                ...payload,
              },
            });
          }
        }
      } else if (listType === 'all') {
        const { planningList } = state;
        for (let i = 0; i < planningList.length; i += 1) {
          if (planningList[i].planid === planId) {
            commit('setPlan', {
              index: i,
              payload: {
                ...planningList[i],
                ...payload,
              },
            });
          }
        }
      } else {
        const { selectedPlan } = state;
        for (let i = 0; i < selectedPlan.length; i += 1) {
          if (selectedPlan[i].planid === planId) {
            commit('setPlanView', {
              index: i,
              payload: {
                ...selectedPlan[i],
                ...payload,
              },
            });
          }
        }
      }
      if (updated) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'PLAN_UPDATED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'PLAN_UPDATED',
        }, {
          root: true,
        });
      }
    },

    deletePlanByPlanId: async ({
      state,
      commit,
      dispatch,
    }, { planId, listType }) => {
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'planning',
          queryParam: `?query=planid=="${planId}"`,
        },
        { root: true },
      );
      if (listType === 'reorder') {
        const { reorderPlanList } = state;
        for (let i = 0; i < reorderPlanList.length; i += 1) {
          if (reorderPlanList[i].planid === planId) {
            commit('removeNotStartedPlan', {
              index: i,
            });
          }
        }
      } else if (listType === 'all') {
        const { planningList } = state;
        for (let i = 0; i < planningList.length; i += 1) {
          if (planningList[i].planid === planId) {
            commit('removePlan', {
              index: i,
            });
          }
        }
      }
      if (deleted) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'PLAN_DELETED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'PLAN_DELETED',
        }, {
          root: true,
        });
      }
      return deleted;
    },

    updatePlanById: async ({ dispatch }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'planning',
          id,
          payload,
        },
        { root: true },
      );
      return updated;
    },

    updateCuringSummary: async ({ dispatch }, { planId, payload }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'curingsummary',
          query: `?query=planid=="${planId}"&pagenumber=1&pagesize=1&sortquery=createdTimestamp==-1`,
          payload,
        },
        { root: true },
      );
      if (records && records.length === 1) {
        const { _id: id } = records[0];
        const updated = await dispatch(
          'element/updateRecordById',
          {
            elementName: 'curingsummary',
            id,
            payload,
          },
          { root: true },
        );
        return updated;
      }
      return true;
    },

    deletePlanById: async ({ dispatch }, id) => {
      const deleted = await dispatch(
        'element/deleteRecordById',
        {
          elementName: 'planning',
          id,
        },
        { root: true },
      );
      return deleted;
    },

    updateMachine: async ({ commit, state, dispatch }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'machine',
          id,
          payload,
        },
        { root: true },
      );
      if (updated) {
        const { machines } = state;
        for (let i = 0; i < machines.length; i += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if (machines[i]._id === id) {
            commit('setMachine', {
              index: i,
              payload: {
                ...machines[i],
                ...payload,
              },
            });
          }
        }
      }
      return updated;
    },

    updatePartMatrix: async ({ dispatch }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'partmatrix',
          id,
          payload,
        },
        { root: true },
      );
      return updated;
    },

    getReportData: async ({ state, dispatch, rootState }, reportName) => {
      const { selectedPlan } = state;
      const { activeSite } = rootState.user;
      const data = await dispatch(
        'report/executeReport',
        {
          reportName,
          payload: {
            siteid: activeSite,
            planFilter: `{${selectedPlan[0].planid}}`,
          },
        },
        { root: true },
      );
      if (data) {
        return JSON.parse(data);
      }
      return null;
    },
  },
  getters: {
    planningSchema: (_, __, rootState, rootGetters) => {
      const { appSchema } = rootState.webApp;
      const licensedAssets = rootGetters['user/licensedAssets'];
      if (appSchema) {
        return appSchema.filter((schema) => licensedAssets.includes(schema.assetId));
      }
      return [];
    },

    partMatrixComposition: (_, { planningSchema }) => (assetId) => {
      if (planningSchema && planningSchema.length) {
        const schema = planningSchema.find((s) => s.assetId === assetId);
        return schema ? schema.partMatrixComposition : [];
      }
      return [];
    },

    isCalendarView: ({ view }) => view !== 'default',

    getAssetName: ({ assets }) => (assetId) => {
      let asset = null;
      if (assets && assets.length && assetId) {
        asset = assets.find((a) => a.id === assetId);
        if (asset) {
          asset = asset.assetName;
        }
      }
      return asset;
    },

    partMatrixTags: ({ partMatrixElement }) => (assetId) => {
      let tags = [];
      if (partMatrixElement && assetId) {
        tags = partMatrixElement.tags.filter((tag) => tag.assetId === assetId && !tag.hide);
      }
      return tags;
    },

    machineList: ({ machines }, _, __, rootGetters) => {
      let machineList = [];
      const { assetid } = rootGetters['webApp/filters'];
      if (machines && machines.length) {
        machineList = sortArray(machines, 'machinename')
          .filter((m) => m.assetid === assetid.value)
          .map(({ machinename }) => ({
            name: machinename,
            value: machinename,
          }));
      }
      return machineList;
    },

    partList: ({ parts }, _, __, rootGetters) => {
      let partList = [];
      const { assetid } = rootGetters['webApp/filters'];
      if (parts && parts.length) {
        partList = sortArray(parts, 'partnumber')
          .filter((m) => m.assetid === assetid.value)
          .map(({ partnumber }) => ({
            name: partnumber,
            value: partnumber,
          }));
      }
      return partList;
    },

    planStatus: () => (status) => {
      let result = { text: 'notStarted', color: 'info' };
      if (status === 'inProgress') {
        result = { text: 'inProgress', color: 'success' };
      } else if (status === 'complete') {
        result = { text: 'complete', color: 'accent' };
      } else if (status === 'abort') {
        result = { text: 'abort', color: 'error' };
      }
      return result;
    },

    getDateRange: ({ shifts }) => ([start, end]) => {
      const [shift] = shifts.sort((a, b) => a.sortindex - b.sortindex);
      const [hr, min] = shift.starttime.split(':');
      const [sYear, sMonth, sDay] = start.split('-');
      const [eYear, eMonth, eDay] = end.split('-');
      const oneDayInMs = 86400000;
      return [
        new Date(
          sYear,
          sMonth - 1,
          sDay,
          parseInt(hr, 10),
          parseInt(min, 10),
          0,
        ).getTime(),
        new Date(
          eYear,
          eMonth - 1,
          eDay,
          parseInt(hr, 10),
          parseInt(min, 10),
          0,
        ).getTime() + oneDayInMs,
      ];
    },

    planning: (
      { planningList },
      { isCalendarView },
      rootState,
      rootGetters,
    ) => {
      let planning = null;
      if (planningList && planningList.length) {
        const [group] = rootGetters['webApp/group'];
        planning = rootGetters['webApp/filteredRecords'](planningList);
        if (!isCalendarView) {
          planning = rootGetters['webApp/sortedRecords'](planning);
          planning = sortArray(planning, group);
          planning = rootGetters['webApp/groupedRecords'](planning);
        }
      }
      if (!planning || !Object.keys(planning).length) {
        planning = null;
      }
      return planning;
    },

    notStartedPlans: ({ reorderPlanList }) => {
      let planning = null;
      if (reorderPlanList && reorderPlanList.length) {
        planning = reorderPlanList.sort((a, b) => a.sortindex - b.sortindex);
        planning = sortArray(planning, 'machinename');
        planning = planning.reduce((result, currentValue) => {
          const key = currentValue.machinename;
          if (!result[key]) {
            result[key] = {};
            result[key].values = [];
          }
          result[key].values = [
            ...result[key].values,
            currentValue,
          ];
          return result;
        }, {});
      }
      if (!planning || !Object.keys(planning).length) {
        planning = null;
      }
      return planning;
    },
  },
});
