import { set } from '@shopworx/services/util/store.helper';
import { sortArray } from '@shopworx/services/util/sort.service';
// import ElementService from '@shopworx/services/api/element.service';

export default ({
  namespaced: true,
  state: {
    columns: [],
    machines: [],
    records: null,
    reports: [],
    shifts: [],
  },
  mutations: {
    setRecords: set('records'),
    setMachines: set('machines'),
    setShifts: set('shifts'),
    setReports: set('reports'),
    setColumns: set('columns'),
  },
  actions: {
    getMachines: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'machine',
          query: '?sortquery=linename==1',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMachines', records);
        return records;
      }
      return false;
    },
    getShifts: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'businesshours',
          query: '?sortquery=sortindex==1',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setShifts', records);
        return true;
      }
      return false;
    },
    getReport: async ({ dispatch, rootState }, { payload, reportName }) => {
      const { activeSite } = rootState.user;
      const reportData = await dispatch(
        'report/executeReport',
        {
          reportName,
          payload: {
            siteid: activeSite,
            ...payload,
          },
        },
        { root: true },
      );
      return JSON.parse(reportData);
    },
    getRecords: async ({ dispatch }, details) => {
      const { elementName, query } = details;
      const result = await dispatch(
        'element/getRecords',
        {
          elementName,
          query,
        },
        { root: true },
      );
      if (result && result.length) {
        return result;
      }
      return false;
    },
  },
  getters: {
    lines: ({ machines }) => {
      const machinelist = sortArray(machines, 'linecode');
      const lines = machinelist.map((m) => m.linename);
      return [...new Set(lines)];
    },
    shiftList: ({ shifts }) => {
      const sortedshift = shifts.sort((a, b) => a.sortindex - b.sortindex);
      const shiftlist = sortedshift.map((shift) => shift.name);
      return [...new Set(shiftlist)];
    },
  },
});
