const routes = [
  {
    path: 'file-manager',
    component: () => import(/* webpackChunkName: "file-manager" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [{
      path: '/',
      name: 'fileManager',
      component: () => import(/* webpackChunkName: "file-home" */ './views/Home.vue'),
    }, {
      path: 'preview',
      name: 'preview',
      component: () => import(/* webpackChunkName: "file-preview" */ './views/Preview.vue'),
    }],
  },
];

export default routes;
