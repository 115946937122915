import axios from 'axios';

class IdapService {
  constructor() {
    this.instance = axios.create({
      baseURL: 'https://api-idap.infinite-uptime.com',
    });
  }

  setToken(token) {
    this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  request(method, url, data = {}, config = {}) {
    return this.instance({
      method,
      url,
      data,
      config,
      responseType: config.responseType || 'json'
    });
  }

  isLoggedIn(token) {
    this.setToken(token);
    return this.request(
      'GET',
      '/api/2.0/admin/isLoggedIn',
      {},
    );
  }
}

export default new IdapService();