const routes = [
  {
    path: 'assembly-planning-taco',
    component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'assemblyPlanningTaco',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/ProductionPlanning.vue'),
      },
      {
        path: 'settings/:id?',
        name: 'assemblyPlanTacoSettings',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/ProductionPlanSettings.vue'),
      },
      {
        path: 'plan',
        name: 'addAssemblyPlanTaco',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/AddProductionPlan.vue'),
      },
      {
        path: 'reorder',
        name: 'reorderAssemblyPlansTaco',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/ReorderProductionPlans.vue'),
      },
      {
        path: 'plan/:id',
        name: 'assemblyPlanDetailsTaco',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/ProductionPlanDetails.vue'),
        props: true,
      },
      {
        path: 'plan/:id/edit',
        name: 'editAssemblyPlanTaco',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/EditProductionPlan.vue'),
        props: true,
      },
      {
        path: 'plan/:id/duplicate',
        name: 'duplicateAssemblyPlanTaco',
        component: () => import(/* webpackChunkName: "assembly-planning-taco" */ './views/DuplicateProductionPlan.vue'),
        props: true,
      },
    ],
  },
];

export default routes;
