import Cookies from 'js-cookie';

class SessionService {
  getIdapToken() {
    return Cookies.get('idap-at');
  }

  getSession() {
    return Cookies.get('sessionId');
  }

  setSession(session) {
    Cookies.set('sessionId', session, { path: '/' });
  }

  removeSession() {
    Cookies.remove('sessionId');
  }
}

export default new SessionService();
