import {
  set, toggle,
} from '@shopworx/services/util/store.helper';
import HourService from '@shopworx/services/api/hour.service';

export default ({
  namespaced: true,
  state: {
    calendarRef: null,
    calendarFocus: '',
    today: new Date().toISOString().substr(0, 10),
    drawer: false,
    view: 'default',
    shifts: [],
    alertData: [],
    loading: false,
    error: false,
    toggleSelection: false,
    lastRefreshedAt: null,
  },
  mutations: {
    setCalendarRef: set('calendarRef'),
    setCalendarFocus: set('calendarFocus'),
    setToggleSelection: set('toggleSelection'),
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    setView: set('view'),
    // setDateRange: set('dateRange'),
    setShifts: set('shifts'),
    setLoading: set('loading'),
    setError: set('error'),
    setAlertData: set('alertData'),
    setLastRefreshedAt: set('lastRefreshedAt'),
  },
  actions: {

    fetchShifts: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'businesshours',
          query: '?sortquery=sortindex==1',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setShifts', records);
        return true;
      }
      return false;
    },

    getScheduledEnd: async (_, { start, duration }) => {
      const { data } = await HourService.getPlanEndTime(start, duration);
      if (data && data.results) {
        return data.results;
      }
      return start + duration;
    },

    getAlertReportData: async ({
      commit, dispatch, getters, rootState, rootGetters,
    }) => {
      const { activeSite } = rootState.user;
      const filters = rootGetters['webApp/filters'];
      let [min, max] = filters.date.value;
      if (!max) {
        max = min;
      }
      // new Date().toISOString().substr(0, 10)
      [min, max] = getters.getDateRange([min, max]);
      min = new Date(min).toISOString().substr(0, 10);
      max = new Date(max).toISOString().substr(0, 10);
      commit('setLoading', true);
      commit('setAlertData', []);
      const data = await dispatch(
        'report/executeReport',
        {
          reportName: 'alert',
          payload: {
            siteid: activeSite,
            start: parseInt(min.replace(/-/g, ''), 10),
            end: parseInt(max.replace(/-/g, ''), 10),
          },
          // payload: {
          //   siteid: activeSite,
          //   start: 20230501,
          //   end: parseInt(max.replace(/-/g, ''), 10),
          // },
        },
        { root: true },
      );
      if (data) {
        commit('setAlertData', JSON.parse(data));
        commit('setLastRefreshedAt', new Date().toLocaleTimeString('en-GB'));
      } else {
        commit('setAlertData', []);
      }
      commit('setLoading', false);
    },
  },
  getters: {
    isCalendarView: ({ view }) => view !== 'default',

    alertStatus: () => (status) => {
      let result = { text: 'notStarted', color: 'info' };
      if (status === 'inProgress') {
        result = { text: 'inProgress', color: 'red' };
      } else if (status === 'complete') {
        result = { text: 'complete', color: 'grey' };
      }
      return result;
    },

    getDateRange: ({ shifts }) => ([start, end]) => {
      const [shift] = shifts.sort((a, b) => a.sortindex - b.sortindex);
      const [hr, min] = shift.starttime.split(':');
      const [sYear, sMonth, sDay] = start.split('-');
      const [eYear, eMonth, eDay] = end.split('-');
      const oneDayInMs = 86400000;
      return [
        new Date(
          sYear,
          sMonth - 1,
          sDay,
          parseInt(hr, 10),
          parseInt(min, 10),
          0,
        ).getTime(),
        new Date(
          eYear,
          eMonth - 1,
          eDay,
          parseInt(hr, 10),
          parseInt(min, 10),
          0,
        ).getTime() + oneDayInMs,
      ];
    },
  },
});
