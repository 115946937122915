import { set, toggle } from '@shopworx/services/util/store.helper';
/* eslint-disable */
export default {
  namespaced: true,
  state: {
    lines: [],
    sublines: [],
    stations: [],
    subStations: [],
    processes: [],
    addSublineDialog: false,
    addStationDialog: false,
    addSubstationDialog: false,
    addProcessDialog: false,
    updateSublineDialog: false,
    updateSubstationDialog: false,
    updateStationDialog: false,
    updateProcessDialog: false,
    allSublines: [],
    allStations: [],
    stationsbylines: [],
    substationsbylines: [],
    selectedLine: {},
    subStationName: {},
    runningOrderList: [],
    roadMapDetailsRecord: [],
    parametersList: [],
    subStationsForIP: [],
    // assets: {},
    assetId: 0,
    filter: false,
    sublineByline: [],
    stationBySubline: [],
    subStationByStation: [],
    processBySubStation: [],
  },
  mutations: {
    setSelectedLine: set('selectedLine'),
    setLines: set('lines'),
    setSublines: set('sublines'),
    setStations: set('stations'),
    setSubStations: set('subStations'),
    setProcesses: set('processes'),
    setAddSublineDialog: set('addSublineDialog'),
    // setAddStationDialog: set('addStationDialog'),
    setAddSubstationDialog: set('addSubstationDialog'),
    setAddProcessDialog: set('addProcessDialog'),
    setUpdateSublineDialog: set('updateSublineDialog'),
    setUpdateStationDialog: set('updateStationDialog'),
    setUpdateSubstationDialog: set('updateSubstationDialog'),
    setUpdateProcessDialog: set('updateProcessDialog'),
    setAllSublines: set('allSublines'),
    setAllStations: set('allStations'),
    setSublinesbyline: set('sublineByline'),
    setStationbySubLines: set('stationBySubline'),
    setSubStationbyStation: set('subStationByStation'),
    setProcessbySubStation: set('processBySubStation'),
    setStationsbyline: set('stationsbylines'),
    setSubStationsbyline: set('substationsbylines'),
    setSubStationName: set('subStationName'),
    setrunningOrderList: set('runningOrderList'),
    setRoadMapDetailsRecord: set('roadMapDetailsRecord'),
    setParametersList: set('parametersList'),
    // setAssets: set('assets'),
    setAssetId: set('assetId'),
    toggleFilter: toggle('filter'),
    setFilter: set('filter'),
  },
  actions: {
    createElementsandTags: async ({ dispatch, state }, elementsAndTags) => {
      const { assetId } = state;
      const createElements = elementsAndTags.map(async (m) => {
        const { element, tags } = m;
        const e = await dispatch('element/getElement', element.elementName, { root: true });
        tags.forEach(tag => {
          tag.assetId = assetId;
        });
        if (!e) {
          const payload = {
            element,
            tags,
          };
          await dispatch('element/createElementAndTags', payload, { root: true });
          // TODO - Enable Kafka binding after platform fix (Restriction - origin user)
          // if (element.elementName.includes('process_')) {
          //   await dispatch('createKafkaBinding', element.elementName);
          // }
        }
      });
      await Promise.all(createElements);
    },
    createKafkaBinding: async ({ dispatch }, elementName) =>{
      const payload = {
        masterElementName: elementName,
        operationType: 'WRITE',
        topics: 'process-operations',
        enable: true,
      }
      let bindingResponse = await dispatch('user/createkafkaBinding', payload, { root: true });
      payload.operationType = 'UPDATE';
      bindingResponse = await dispatch('user/createkafkaBinding', payload, { root: true });
      if (bindingResponse) {
        return true;
      }
      return false;
    },
    getAssets: async ({ commit, rootGetters }) => {
      const licensedAssets = rootGetters['user/licensedAssets'];
      if (licensedAssets && licensedAssets.length) {
        commit('setAssetId', licensedAssets[0]);
        return true;
      } else {
        return false;
      }
    },
    getSubStationIdElement: async ({ dispatch }, object) => {
      const elementsArray = await dispatch('element/getElementsBySite', object.siteId, {
        root: true,
      });
      const elementsToDelete = [];
      for (const elementObject of elementsArray) {
        if (elementObject.element.elementName.includes(object.substationid)) {
          elementsToDelete.push(elementObject);
        }
      }
      if (elementsToDelete) {
        return elementsToDelete;
      }
      return false;
    },
    inactiveElement: async ({ dispatch }, object) => {
      const results = await dispatch(
        'element/changeElementStatusById',
        {
          payload: object,
          elementId: object.elementId,
        },
        { root: true },
      );
      if (results) {
        return results;
      }
      return false;
    },
    getParameterList: async ({ dispatch, commit }) => {
      commit('setParametersList', []);
      const parameterRecords = await dispatch(
        'element/getRecords',
        {
          elementName: 'parameters',
        },
        { root: true },
      );
      commit('setParametersList', parameterRecords);
    },
    downloadToPLC: async ({ dispatch }, payload) => {
      const substations = await dispatch(
        'element/postSocket',
        {
          functionName: 'parameterupload',
          payload,
        },
        { root: true },
      );
      return substations;
    },
    getRunningOrder: async ({ dispatch, commit }, query) => {
      commit('setrunningOrderList', []);
      query = '?query=orderstatus=="Running"';
      const runningOrderList = await dispatch(
        'element/getRecords',
        {
          elementName: 'order',
          query,
        },
        { root: true },
      );
      if (runningOrderList && runningOrderList.length) {
        commit('setrunningOrderList', runningOrderList);
        return true;
      } else {
        return false;
      }
    },
    getRoadMapDetailsRecord: async ({ dispatch, commit }) => {
      commit('setRoadMapDetailsRecord', []);
      const roadMapDetailsRecord = await dispatch(
        'element/getRecords',
        {
          elementName: 'roadmapdetails',
        },
        { root: true },
      );
      if (roadMapDetailsRecord && roadMapDetailsRecord.length) {
        commit('setRoadMapDetailsRecord', roadMapDetailsRecord);
      }
    },
    getLines: async ({ dispatch, commit }, query) => {
      commit('setLines', []);
      const lineList = await dispatch(
        'element/getRecords',
        {
          elementName: 'line',
          query,
        },
        { root: true },
      );
      if (lineList && lineList.length) {
        commit('setLines', lineList);
        return true;
      } else {
        return false;
      }
    },
    getSublines: async ({ dispatch, commit }, query) => {
      commit('setSublines', []);
      const sublines = await dispatch(
        'element/getRecords',
        {
          elementName: 'subline',
          query,
        },
        { root: true },
      );
      if (sublines && sublines.length) {
        commit('setSublines', sublines);
      }
    },
    getStations: async ({ dispatch, commit }, query) => {
      commit('setStations', []);
      const stations = await dispatch(
        'element/getRecords',
        {
          elementName: 'station',
          query,
        },
        { root: true },
      );
      if (stations && stations.length) {
        commit('setStations', stations);
        return true;
      } else {
        return false;
      }
    },
    getfilteredSubline: async ({ dispatch, commit }, query) => {
      commit('setSublinesbyline', []);
      const sublinebyLines = await dispatch(
        'element/getRecords',
        {
          elementName: 'subline',
          query,
        },
        { root: true },
      );
      if (sublinebyLines && sublinebyLines.length) {
        commit('setSublinesbyline', sublinebyLines);
        return true;
      } else {
        return false;
      }
    },
    getfilteredStation: async ({ dispatch, commit }, query) => {
      commit('setStationbySubLines', []);
      const stationbySubLines = await dispatch(
        'element/getRecords',
        {
          elementName: 'station',
          query,
        },
        { root: true },
      );
      if (stationbySubLines && stationbySubLines.length) {
        commit('setStationbySubLines', stationbySubLines);
        return true;
      } else {
        return false;
      }
    },
    getfilteredSubStation: async ({ dispatch, commit }, query) => {
      commit('setSubStationbyStation', []);
      const subStationbyStation = await dispatch(
        'element/getRecords',
        {
          elementName: 'substation',
          query,
        },
        { root: true },
      );
      if (subStationbyStation && subStationbyStation.length) {
        commit('setSubStationbyStation', subStationbyStation);
        return true;
      } else {
        return false;
      }
    },
    getfilteredProcess: async ({ dispatch, commit }, query) => {
      commit('setProcessbySubStation', []);
      const processbySubStation = await dispatch(
        'element/getRecords',
        {
          elementName: 'process',
          query,
        },
        { root: true },
      );
      if (processbySubStation && processbySubStation.length) {
        commit('setProcessbySubStation', processbySubStation);
        return true;
      } else {
        return false;
      }
    },
    getStationbyline: async ({ dispatch, commit }, query) => {
      commit('setStationsbyline', []);
      const stationsbylines = await dispatch(
        'element/getRecords',
        {
          elementName: 'station',
          query,
        },
        { root: true },
      );
      if (stationsbylines && stationsbylines.length) {
        commit('setStationsbyline', stationsbylines);
        return true;
      } else {
        return false;
      }
    },
    getSubStationbyline: async ({ dispatch, commit }, query) => {
      // const query = `?query=lineid==${this.selectedLine.id}`;
      commit('setSubStationsbyline', []);
      const substationsbylines = await dispatch(
        'element/getRecords',
        {
          elementName: 'substation',
          query,
        },
        { root: true },
      );
      if (substationsbylines && substationsbylines.length) {
        commit('setSubStationsbyline', substationsbylines);
        return true;
      } else {
        return false;
      }
    },
    getAllSublines: async ({ dispatch, commit }, query) => {
      commit('setAllSublines', []);
      const allSublines = await dispatch(
        'element/getRecords',
        {
          elementName: 'subline',
          query,
        },
        { root: true },
      );
      if (allSublines && allSublines.length) {
        commit('setAllSublines', allSublines);
        return true;
      } else {
        return false;
      }
    },
    getAllStations: async ({ dispatch, commit }, query) => {
      commit('setAllStations', []);
      const allStations = await dispatch(
        'element/getRecords',
        {
          elementName: 'station',
          query,
        },
        { root: true },
      );
      if (allStations && allStations.length) {
        commit('setAllStations', allStations);
        return true;
      } else {
        return false;
      }
    },
    getSubStations: async ({ dispatch, commit }, query) => {
      commit('setSubStations', []);
      const list = await dispatch(
        'element/getRecords',
        {
          elementName: 'substation',
          query,
        },
        { root: true },
      );
      let subStations = [];
      if (list && list.length) {
        subStations = list.map((l) => ({
          ...l,
          stationcolor: 0,
          // numberIndex: index + 1,
        }));
        commit('setSubStations', subStations);
        return true;
      } else {
        return false;
      }
    },
    getSubStationName: async ({ dispatch, commit }, query) => {
      commit('setSubStationName', []);
      const subStations = await dispatch(
        'element/getRecords',
        {
          elementName: 'substation',
          query,
        },
        { root: true },
      );
      if (subStations && subStations.length) {
        commit('setSubStationName', subStations);
        return true;
      } else {
        return false;
      }
    },
    getProcesses: async ({ dispatch, commit }, query) => {
      commit('setProcesses', []);
      const processes = await dispatch(
        'element/getRecords',
        {
          elementName: 'process',
          query,
        },
        { root: true },
      );
      if (processes && processes.length) {
        commit('setProcesses', processes);
        return true;
      } else {
        return false;
      }
    },
    createSubline: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'subline',
          payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${payload.lineid}`;
        await dispatch('getSublines', query);
      }
      return created;
    },
    createStation: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'station',
          payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${payload.lineid}`;
        await dispatch('getStations', query);
      }
      return created;
    },
    createSubstation: async ({ dispatch }, payload) => {
      let list = [];
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'substation',
          payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${payload.lineid}`;
        await dispatch('getSubStations', query);
      }
      return created;
    },
    createProcess: async ({ dispatch, commit }, payload) => {
      let list = [];
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'process',
          payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${payload.lineid}`;
        dispatch('getProcesses', query);
      }
      return created;
    },
    createLine: async ({ dispatch, commit }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'line',
          payload,
        },
        { root: true },
      );
      if (created) {
        await dispatch('getLines');
      }
      return created;
    },
    updateLine: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'line',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      return created;
    },
    updateSubline: async ({ dispatch, commit }, payload) => {
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'subline',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${payload.lineid}`;
        dispatch('getSublines', query);
      }
      return created;
    },
    updateMainLineFlagToSubStations: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'substation',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      return created;
    },
    updateProcess: async ({ state, dispatch }, payload) => {
      const { selectedLine } = state;
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'process',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${selectedLine.id}`;
        dispatch('getProcesses', query);
      }
      return created;
    },
    updateSubstation: async ({ state, dispatch }, payload) => {
      const { selectedLine } = state;
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'substation',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${selectedLine.id}`;
        await dispatch('getSubStations', query);
      }
      return created;
    },
    updateStation: async ({ state, dispatch }, payload) => {
      const { selectedLine } = state;
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'station',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      if (created) {
        const query = `?query=lineid==${selectedLine.id}`;
        dispatch('getStations', query);
      }
      return created;
    },
    updateIpAddressForParameters: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'parameters',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      return created;
    },
    deleteProcess: async ({ state, dispatch }, id) => {
      const { selectedLine } = state;
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'process',
          queryParam: `?query=id=="${id}"`,
        },
        { root: true },
      );
      if (deleted) {
        const query = `?query=lineid==${selectedLine.id}`;
        dispatch('getProcesses', query);
      }
      return deleted;
    },
    deleteSubline: async ({ dispatch }, object) => {
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'subline',
          queryParam: `?query=id=="${object.id}"`,
        },
        { root: true },
      );
      if (deleted) {
        // TODO - call delete station
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'station',
            queryParam: `?query=sublineid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        // TODO - call delete substation
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'substation',
            queryParam: `?query=sublineid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'parameters',
            queryParam: `?query=sublineid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        // TODO - call delete process
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'process',
            queryParam: `?query=sublineid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted && object.roadmapid) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'roadmaplist',
            queryParam: `?query=id=="${object.roadmapid}"`,
          },
          { root: true },
        );
      }
      if (deleted && object.roadmapid) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'roadmapdetails',
            queryParam: `?query=roadmapid=="${object.roadmapid}"`,
          },
          { root: true },
        );
      }
      if (deleted && object.roadmapid) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'order',
            queryParam: `?query=roadmapid=="${object.roadmapid}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        const query = `?query=lineid==${object.lineid}`;
        dispatch('getSublines', query);
      }
      return deleted;
    },
    deleteStation: async ({ state, dispatch }, object) => {
      const { selectedLine } = state;
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'station',
          queryParam: `?query=id=="${object.id}"`,
        },
        { root: true },
      );
      if (deleted) {
        // TODO - call delete substation
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'substation',
            queryParam: `?query=stationid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'parameters',
            queryParam: `?query=stationid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        // TODO - call delete process
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'process',
            queryParam: `?query=stationid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'roadmaplist',
            queryParam: `?query=id=="${object.roadmapid}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'roadmapdetails',
            queryParam: `?query=roadmapid=="${object.roadmapid}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'order',
            queryParam: `?query=roadmapid=="${object.roadmapid}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        const query = `?query=lineid==${selectedLine.id}`;
        dispatch('getStations', query);
      }
      return deleted;
    },
    deleteSubstation: async ({ state, dispatch }, object) => {
      const { selectedLine } = state;
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'substation',
          queryParam: `?query=id=="${object.id}"`,
        },
        { root: true },
      );
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'process',
            queryParam: `?query=substationid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted && object.elements) {
        for (const element of object.elements) {
          element.element.status = object.status;
          await dispatch(
            'element/changeElementStatusById',
            {
              payload: element.element,
              elementId: element.element.id,
            },
            { root: true },
          );
          const payloadData = [];
          element.tags.forEach((tag) => {
            payloadData.push({ tagId: tag.id, elementId: tag.elementId, status: object.status });
          });
          await dispatch(
            'element/updateTagById',
            {
              payload: payloadData,
            },
            { root: true },
          );
        }
      }
      if (deleted) {
        await dispatch(
          'element/deleteRecordByQuery',
          {
            elementName: 'parameters',
            queryParam: `?query=substationid=="${object.id}"`,
          },
          { root: true },
        );
      }
      if (deleted) {
        const query = `?query=lineid==${selectedLine.id}`;
        dispatch('getSubStations', query);
      }
      return deleted;
    },
    inactiveRealElement: async ({ dispatch }, payload) => {
      const updated = await dispatch(
        'element/updateElementStatusById',
        {
          id: payload.elementId,
          payload: { status: payload.status },
        },
        { root: true },
      );
      return updated;
    },
    inactiveProcessElement: async ({ dispatch }, payload) => {
      const updated = await dispatch(
        'element/updateElementStatusById',
        {
          id: payload.elementId,
          payload: { status: payload.status },
        },
        { root: true },
      );
      return updated;
    },
  },
};
