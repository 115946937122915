const routes = [
  {
    path: 'production-log',
    component: () => import(/* webpackChunkName: "production-log" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'productionLog',
        component: () => import(/* webpackChunkName: "production-log" */ './views/ProductionLog.vue'),
      },
      {
        path: 'settings/:id?',
        name: 'productionLogSettings',
        component:
        () => import(/* webpackChunkName: "production-log" */ './views/ProductionLogSettings.vue'),
      },
    ],
  },
];

export default routes;
