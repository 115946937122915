export default {
  // Set Filter
  selectAll: '(选择所有)',
  selectAllSearchResults: '(选择所有查询结果)',
  searchOoo: '查询中...',
  blanks: '(空白)',
  noMatches: '无匹配项',

  // Number Filter & Text Filter
  filterOoo: '筛选...',
  equals: '等于',
  notEqual: '不等于',
  empty: '选择一个',

  // Number Filter
  lessThan: '小于',
  greaterThan: '大于',
  lessThanOrEqual: '小于或等于',
  greaterThanOrEqual: '大于或等于',
  inRange: '在范围内',
  inRangeStart: '到',
  inRangeEnd: '从',

  // Text Filter
  contains: '包含',
  notContains: '不包含',
  startsWith: '开始于',
  endsWith: '结束于',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: '与',
  orCondition: '或',

  // Filter Buttons
  applyFilter: '应用',
  resetFilter: '复位',
  clearFilter: '清楚',
  cancelFilter: '取消',

  // Filter Titles
  textFilter: '文本筛选',
  numberFilter: '数字筛选',
  dateFilter: '日期筛选',
  setFilter: '设置筛选',

  // Side Bar
  columns: '列',
  filters: '筛选',

  // columns tool panel
  pivotMode: '透视模式',
  groups: '行组',
  rowGroupColumnsEmptyMessage: '在这里拖动以设置行组',
  values: '数值',
  valueColumnsEmptyMessage: '拖动到这里进行集合',
  pivots: '列标签',
  pivotColumnsEmptyMessage: '拖到这里进行列标签设置',

  // Header of the Default Group Column
  group: '组',

  // Other
  loadingOoo: '加载中...',
  noRowsToShow: '没有更多的行显示',
  enabled: '启用',

  // Menu
  pinColumn: '锁定列',
  pinLeft: '锁定在左侧',
  pinRight: '锁定在右侧',
  noPin: '没有锁定',
  valueAggregation: '数值集合',
  autosizeThiscolumn: '本列自适应',
  autosizeAllColumns: '所有列自适应',
  groupBy: '分组',
  ungroupBy: '取消分组',
  resetColumns: '复位所有列',
  expandAll: '全部展开',
  collapseAll: '全部折叠',
  copy: '复制',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: '与标题一起复制',
  paste: '粘贴',
  ctrlV: 'Ctrl+V',
  export: '导出',
  csvExport: '以CSV文件格式导出',
  excelExport: '以Excel文件格式导出',

  // Enterprise Menu Aggregation and Status Bar
  sum: '求和',
  min: '最小值',
  max: '最大值',
  none: '无',
  count: '计数',
  avg: '求平均',
  filteredRows: '已筛选',
  selectedRows: '已选择',
  totalRows: '所有行',
  totalAndFilteredRows: '行数',
  more: '更多',
  to: 'to',
  of: 'of',
  page: '页',
  nextPage: '下一页',
  lastPage: '最后一页',
  firstPage: '第一页',
  previousPage: '上一页',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: '透视图 & 透视模式',
  pivotChart: '透视图',
  chartRange: '图表范围',

  columnChart: '柱状图',
  groupedColumn: '分组',
  stackedColumn: '堆叠',
  normalizedColumn: '100%比例堆叠',

  barChart: '条状图',
  groupedBar: '分组',
  stackedBar: '堆叠',
  normalizedBar: '100%比例堆叠',

  pieChart: '饼图',
  pie: '饼图',
  doughnut: '圆环图',

  line: '折线图',

  xyChart: 'X Y 散点图)',
  scatter: '散点图',
  bubble: '气泡图',

  areaChart: '区域图',
  area: '区域',
  stackedArea: '堆叠',
  normalizedArea: '100%比例堆叠',

  histogramChart: '直方图',

  // Charts
  pivotChartTitle: '透视图',
  rangeChartTitle: '范围图',
  settings: '设置项',
  data: '日期',
  format: '格式',
  categories: '分类',
  defaultCategory: '(无)',
  series: '系列',
  xyValues: 'X Y 值',
  paired: '搭配模式',
  axis: '轴',
  navigator: '导航',
  color: '颜色',
  thickness: '厚度',
  xType: 'X 类',
  automatic: '自动的',
  category: '类别',
  number: '数字',
  time: '时间',
  xRotation: 'X 旋转',
  yRotation: 'Y 旋转',
  ticks: '钩号',
  width: '宽度',
  height: '高度',
  length: '长度',
  padding: '填充',
  spacing: '间距',
  chart: '图表',
  title: '标题',
  titlePlaceholder: '图表标题 - 双击编辑',
  background: '背景',
  font: '字体',
  top: '顶端',
  right: '右端',
  bottom: '底部',
  left: '左端',
  labels: '标签',
  size: '尺寸',
  minSize: '最小尺寸',
  maxSize: '最大尺寸',
  legend: '图例',
  position: '位置',
  markerSize: '图例尺寸',
  markerStroke: '图例外线',
  markerPadding: '图例填充',
  itemSpacing: '项间距',
  itemPaddingX: '项填充 X',
  itemPaddingY: '项填充 Y',
  layoutHorizontalSpacing: '水平间距',
  layoutVerticalSpacing: '垂直间距',
  strokeWidth: '外线宽度',
  offset: '偏移',
  offsets: '偏移量',
  tooltips: '工具提示',
  callout: '插图标号',
  markers: '标记',
  shadow: '阴影',
  blur: '模糊',
  xOffset: 'X 偏移',
  yOffset: 'Y 偏移',
  lineWidth: '线宽度',
  normal: '正常',
  bold: '粗体',
  italic: '斜体',
  boldItalic: '粗体斜体',
  predefined: '预定义',
  fillOpacity: '填充透明度',
  strokeOpacity: '线条透明度',
  histogramBinCount: '出现频率',
  columnGroup: '列',
  barGroup: '条形图',
  pieGroup: '饼图',
  lineGroup: '折线图',
  scatterGroup: 'X Y (散点图)',
  areaGroup: '区域图',
  histogramGroup: '直方图',
  groupedColumnTooltip: '分组',
  stackedColumnTooltip: '堆叠',
  normalizedColumnTooltip: '100%比例堆叠',
  groupedBarTooltip: '分组图',
  stackedBarTooltip: '堆叠图',
  normalizedBarTooltip: '100%比例堆叠',
  pieTooltip: '饼图',
  doughnutTooltip: '圆环图',
  lineTooltip: '折线图',
  groupedAreaTooltip: '区域图',
  stackedAreaTooltip: '堆叠图',
  normalizedAreaTooltip: '100%比例堆叠',
  scatterTooltip: '堆叠图',
  bubbleTooltip: '气泡图',
  histogramTooltip: '直方图',
  noDataToChart: '没有有效数据可以图表显示',
  pivotChartRequiresPivotMode: '透视图需要启用透视模式',
  chartSettingsToolbarTooltip: '菜单',
  chartLinkToolbarTooltip: '连接到报表',
  chartUnlinkToolbarTooltip: '不连接到报表',
  chartDownloadToolbarTooltip: '下载图表',

  // ARIA
  ariaHidden: '隐藏',
  ariaVisible: '可见',
  ariaChecked: '已检查',
  ariaUnchecked: '未检查',
  ariaIndeterminate: '不确定的',
  ariaColumnSelectAll: '切换选择所有列',
  ariaInputEditor: '输入编辑器',
  ariaDateFilterInput: '筛选输入日期',
  ariaFilterInput: '筛选输入',
  ariaFilterColumnsInput: '筛选列输入',
  ariaFilterValue: '筛选数值',
  ariaFilterFromValue: '从数值筛选',
  ariaFilterToValue: '筛选到数值',
  ariaFilteringOperator: '筛选操作员',
  ariaColumnToggleVisibility: '列切换可见',
  ariaColumnGroupToggleVisibility: '列组切换可见',
  ariaRowSelect: '按空格键选择这一行',
  ariaRowDeselect: '按空格键取消选中这一行',
  ariaRowToggleSelection: '按空格键切换行选择',
  ariaRowSelectAll: '按空格键切换所有行选择',
  ariaSearch: '搜索',
  ariaSearchFilterValues: '搜素筛选值',
};
