import AuthService from '@shopworx/services/api/auth.service';
import LocaleService from '@shopworx/services/util/locale.service';
import { set, toggle } from '@shopworx/services/util/store.helper';
import i18n from '../../i18n';
import AG_GRID_LOCALE_EN from '../../plugins/ag-grid-locale/locale.en';
import AG_GRID_LOCALE_CN from '../../plugins/ag-grid-locale/locale.cn';

export default ({
  state: {
    appConfig: null,
    version: process.env.VUE_APP_VERSION || '0',
    alert: {
      show: false,
      type: null,
      message: null,
    },
    userAgent: navigator.userAgent,
    isSessionValid: true,
    locales: [
      {
        text: 'English',
        value: 'en',
      },
      {
        text: 'हिन्दी',
        value: 'hi',
      },
      {
        text: '中文',
        value: 'zhHans',
      },
      {
        text: 'ไทย',
        value: 'th',
      },
      {
        text: 'Deutsche',
        value: 'de',
      },
    ],
    avatars: [
      'beam',
      'bauhaus',
      'pixel',
      'ring',
    ],
    currentAvatar: '',
    currentLocale: LocaleService.getLocale(),
    isDark: null,
    insightsDrawer: false,
    extendedHeader: false,
    infinityLoading: false,
    isConnected: true,
    termsService: false,
  },
  mutations: {
    setAppConfig: set('appConfig'),
    setAlert: set('alert'),
    setIsSessionValid: set('isSessionValid'),
    setIsDark: set('isDark'),
    toggleIsDark: toggle('isDark'),
    setExtendedHeader: set('extendedHeader'),
    setInfinityLoading: toggle('infinityLoading'),
    setInsightsDrawer: set('insightsDrawer'),
    toggleInsightsDrawer: toggle('insightsDrawer'),
    setIsConnected: set('isConnected'),
    setCurrentLocale: set('currentLocale'),
    setCurrentAvatar: set('currentAvatar'),
    setTermsServices: set('termsService'),
  },
  actions: {
    getServerTime: async ({ commit, rootState }) => {
      const { sessionId } = rootState.auth;
      try {
        const { data } = await AuthService.getServerTime(sessionId);
        if (data && data.results) {
          return true;
        }
      } catch (e) {
        commit('setIsConnected', false);
        return false;
      }
      commit('setIsConnected', false);
      return false;
    },
    getSchemaAndLabel: async ({ dispatch, state, rootState }, appId) => {
      const { currentLocale } = state;
      await dispatch('webApp/getAppSchema', appId, { root: true });
      const { overrideLabel } = rootState.webApp;
      if (overrideLabel) {
        i18n.mergeLocaleMessage(currentLocale, overrideLabel);
      }
    },
  },
  getters: {
    primaryLogo: ({ appConfig }) => appConfig.primaryLogo,

    footer: ({ appConfig }) => appConfig.footer,

    primaryText: ({ appConfig }) => appConfig.primaryText,

    isWebView: ({ userAgent }) => userAgent.includes('wv'),

    isIos: ({ userAgent }) => {
      const ids = ['iPhone', 'iPad'];
      return ids.find((id) => userAgent.includes(id));
    },

    agGridLocaleText: () => {
      const { locale } = i18n;
      switch (locale) {
        case 'zhHans':
          return AG_GRID_LOCALE_CN;
        default:
          return AG_GRID_LOCALE_EN;
      }
    },

    agGridTheme: ({ isDark }) => (isDark
      ? 'ag-theme-balham-dark'
      : 'ag-theme-balham'),

    locale: ({ currentLocale }) => {
      if (currentLocale === 'zhHans') {
        return 'zh';
      }
      return currentLocale;
    },
  },
});
