import {
  set, toggle, reactiveSetArray, reactiveRemoveArray,
} from '@shopworx/services/util/store.helper';
import { sortArray } from '@shopworx/services/util/sort.service';

export default ({
  namespaced: true,
  state: {
    drawer: false,
    assets: [],
    machines: [],
    solutions: [],
    crons: [],
    operators: [],
    planningList: [],
    loading: false,
    error: false,
    planningCount: 0,
    toggleSelection: false,
    lastRefreshedAt: null,
    selectedPlan: [],
    spareparts: [],
  },
  mutations: {
    setToggleSelection: set('toggleSelection'),
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    setAssets: set('assets'),
    setMachines: set('machines'),
    setSolutions: set('solutions'),
    setCrons: set('crons'),
    setOperators: set('operators'),
    setSpareparts: set('spareparts'),
    setLoading: set('loading'),
    setError: set('error'),
    setPlanningCount: set('planningCount'),
    setPlanningList: set('planningList'),
    setPlan: reactiveSetArray('planningList'),
    setSparepart: reactiveSetArray('spareparts'),
    setPlanView: reactiveSetArray('selectedPlan'),
    removePlan: reactiveRemoveArray('planningList'),
    removeSparepart: reactiveRemoveArray('spareparts'),
    setLastRefreshedAt: set('lastRefreshedAt'),
    setSelectedPlan: set('selectedPlan'),
  },
  actions: {
    fetchAssets: async ({ commit, dispatch }) => {
      const assets = await dispatch(
        'industry/getAssets',
        null,
        { root: true },
      );
      if (assets && assets.length) {
        commit('setAssets', assets);
        return true;
      }
      return false;
    },

    fetchMachines: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'machine',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMachines', records);
        return true;
      }
      return false;
    },

    fetchSolutions: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'solution',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSolutions', records);
        return true;
      }
      return false;
    },

    fetchCrons: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'cron',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setCrons', records);
        return true;
      }
      return false;
    },

    fetchOperators: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'operator',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setOperators', records);
        return true;
      }
      return false;
    },

    fetchPlanningList: async ({ commit, dispatch }) => {
      commit('setLoading', true);
      commit('setPlanningList', []);
      commit('setError', false);
      const data = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'maintenanceplan',
        },
        { root: true },
      );
      if (data && data.results) {
        const plans = data.results;
        commit('setPlanningList', plans);
        commit('setPlanningCount', data.totalCount);
        commit('setError', false);
        commit('setLastRefreshedAt', new Date().toLocaleTimeString('en-GB'));
      } else {
        commit('setPlanningList', []);
        commit('setPlanningCount', 0);
        commit('setError', true);
      }
      commit('setLoading', false);
    },

    fetchPlan: async ({ commit, dispatch }, planId) => {
      commit('setSelectedPlan', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'maintenanceplan',
          query: `?query=planid=="${planId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSelectedPlan', records);
        return true;
      }
      commit('setSelectedPlan', []);
      return false;
    },

    fetchMachineSpareParts: async ({ dispatch }, machineId) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'sparepartbindmachineposition',
          query: `?query=machineid=="${machineId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        const parts = sortArray(records, 'sparepartname');
        return parts;
      }
      return [];
    },

    fetchRecentTasks: async ({ dispatch }, planId) => {
      const records = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'task',
          query: `?query=planid=="${planId}"&pagenumber=1&pagesize=10&sortquery=createdTimestamp==-1`,
        },
        { root: true },
      );
      if (records) {
        return records;
      }
      return [];
    },

    fetchPlanAdherence: async ({ dispatch }, planId) => {
      const allPlans = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'task',
          query: `?query=planid=="${planId}"&pagenumber=1&pagesize=1`,
        },
        { root: true },
      );
      const completedPlans = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'task',
          query: `?query=planid=="${planId}"%26%26status=="completed"&pagenumber=1&pagesize=1`,
        },
        { root: true },
      );
      const records = await Promise.all([allPlans, completedPlans]);
      const total = (records[0] && records[0].totalCount) || 0;
      const completed = (records[1] && records[1].totalCount) || 0;
      const adherence = parseFloat((completed / total) * 100).toFixed(2);
      return {
        total,
        completed,
        adherence,
      };
    },

    fetchTaskOperators: async ({ dispatch }, taskId) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'taskoperator',
          query: `?query=taskid=="${taskId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        return records;
      }
      return [];
    },

    fetchSpareParts: async ({ commit, dispatch }, planId) => {
      commit('setSpareparts', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'sparepartsinplanning',
          query: `?query=planid=="${planId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSpareparts', records);
        return true;
      }
      return false;
    },

    deleteSparepartById: async ({
      state,
      commit,
      dispatch,
    }, id) => {
      const deleted = await dispatch(
        'element/deleteRecordById',
        {
          elementName: 'sparepartsinplanning',
          id,
        },
        { root: true },
      );
      if (deleted) {
        const { spareparts } = state;
        for (let i = 0; i < spareparts.length; i += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if (spareparts[i]._id === id) {
            commit('removeSparepart', {
              index: i,
            });
          }
        }
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'DELETED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'DELETED',
        }, {
          root: true,
        });
      }
      return deleted;
    },

    updateSparepartById: async ({
      state,
      commit,
      dispatch,
    }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'sparepartsinplanning',
          id,
          payload,
        },
        { root: true },
      );
      if (updated) {
        const { spareparts } = state;
        for (let i = 0; i < spareparts.length; i += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if (spareparts[i]._id === id) {
            commit('setSparepart', {
              index: i,
              payload: {
                ...spareparts[i],
                ...payload,
              },
            });
          }
        }
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'UPDATED',
        }, {
          root: true,
        });
        return true;
      }
      commit('helper/setAlert', {
        show: true,
        type: 'error',
        message: 'UPDATED',
      }, {
        root: true,
      });
      return false;
    },

    addSparepart: async ({ state, commit, dispatch }, payload) => {
      const added = await dispatch(
        'element/postRecord',
        {
          elementName: 'sparepartsinplanning',
          payload,
        },
        { root: true },
      );
      if (added) {
        const { spareparts } = state;
        const item = { ...payload, _id: added.id };
        commit('setSpareparts', [
          item,
          ...spareparts,
        ]);
      }
      return added;
    },

    createPlan: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'maintenanceplan',
          payload,
        },
        { root: true },
      );
      return created;
    },

    updatePlanByPlanId: async ({
      state,
      commit,
      dispatch,
    }, { planId, payload, listType }) => {
      const updated = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'maintenanceplan',
          queryParam: `?query=planid=="${planId}"`,
          payload,
        },
        { root: true },
      );
      if (listType === 'all') {
        const { planningList } = state;
        for (let i = 0; i < planningList.length; i += 1) {
          if (planningList[i].planid === planId) {
            commit('setPlan', {
              index: i,
              payload: {
                ...planningList[i],
                ...payload,
              },
            });
          }
        }
      } else {
        const { selectedPlan } = state;
        for (let i = 0; i < selectedPlan.length; i += 1) {
          if (selectedPlan[i].planid === planId) {
            commit('setPlanView', {
              index: i,
              payload: {
                ...selectedPlan[i],
                ...payload,
              },
            });
          }
        }
      }
      if (updated) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'PLAN_UPDATED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'PLAN_UPDATED',
        }, {
          root: true,
        });
      }
    },

    deletePlanByPlanId: async ({
      state,
      commit,
      dispatch,
    }, { planId, listType }) => {
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'maintenanceplan',
          queryParam: `?query=planid=="${planId}"`,
        },
        { root: true },
      );
      if (listType === 'all') {
        const { planningList } = state;
        for (let i = 0; i < planningList.length; i += 1) {
          if (planningList[i].planid === planId) {
            commit('removePlan', {
              index: i,
            });
          }
        }
      }
      if (deleted) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'PLAN_DELETED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'PLAN_DELETED',
        }, {
          root: true,
        });
      }
      return deleted;
    },

    updatePlanById: async ({ dispatch }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'maintenanceplan',
          id,
          payload,
        },
        { root: true },
      );
      return updated;
    },

    deletePlanById: async ({ dispatch }, id) => {
      const deleted = await dispatch(
        'element/deleteRecordById',
        {
          elementName: 'maintenanceplan',
          id,
        },
        { root: true },
      );
      return deleted;
    },
  },
  getters: {
    selectedAsset: ({ assets }) => (assetId) => {
      let asset = null;
      if (assets && assets.length && assetId) {
        asset = assets.find((a) => a.id === assetId);
        if (asset) {
          asset = asset.assetName;
        }
      }
      return asset;
    },

    machineList: ({ machines }) => {
      let machineList = [];
      if (machines && machines.length) {
        machineList = sortArray(machines, 'machinename').map(({
          machinename,
          assetid,
          id,
          machinecode,
        }) => ({
          name: machinename,
          value: machinename,
          assetid,
          id,
          machinecode,
        }));
      }
      return machineList;
    },

    solutionList: ({ solutions }) => {
      let solutionList = [];
      if (solutions && solutions.length) {
        solutionList = sortArray(solutions, 'name').map(({
          name,
          id,
          type,
        }) => ({
          name,
          id,
          type,
        }));
      }
      return solutionList;
    },

    cronList: ({ crons }) => {
      let cronList = [];
      if (crons && crons.length) {
        cronList = sortArray(crons, 'name').map(({ name, cron }) => ({
          name,
          cron,
        }));
      }
      return cronList;
    },

    planStatus: () => (status) => {
      let result = { text: '', color: '' };
      if (status === 'enable') {
        result = { text: 'active', color: 'success' };
      } else if (status === 'unable') {
        result = { text: 'inactive', color: 'error' };
      }
      return result;
    },

    taskStatus: () => (status) => {
      let result = { text: 'notStarted', color: 'info' };
      if (status === 'in progress') {
        result = { text: 'inProgress', color: 'success' };
      } else if (status === 'completed') {
        result = { text: 'complete', color: 'accent' };
      } else if (status === 'assigned') {
        result = { text: 'assigned', color: 'warning' };
      }
      return result;
    },

    planning: (
      { planningList },
      { isCalendarView },
      rootState,
      rootGetters,
    ) => {
      let planning = null;
      if (planningList && planningList.length) {
        const [group] = rootGetters['webApp/group'];
        planning = rootGetters['webApp/filteredRecords'](planningList);
        if (!isCalendarView) {
          planning = rootGetters['webApp/sortedRecords'](planning);
          planning = sortArray(planning, group);
          planning = rootGetters['webApp/groupedRecords'](planning);
        }
      }
      if (!planning || !Object.keys(planning).length) {
        planning = null;
      }
      return planning;
    },
  },
});
