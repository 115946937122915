const routes = [
  {
    path: 'data-analyzer',
    name: 'dataAnalyzer',
    component: () => import(/* webpackChunkName: "data-visualizer" */ './views/DataAnalyzer.vue'),
    meta: {
      permissionRequired: true,
    },
  },
];
export default routes;
