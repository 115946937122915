import { set, toggle } from '@shopworx/services/util/store.helper';
import { sortArray } from '@shopworx/services/util/sort.service';

export default ({
  namespaced: true,
  state: {
    drawer: true,
    cells: [],
    shifts: [],
    manpowerList: [],
    loading: false,
    error: false,
    manpowerCount: 0,
    lastRefreshedAt: null,
    targetManpower: null,
  },
  mutations: {
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    setCells: set('cells'),
    setTargetManpower: set('targetManpower'),
    setShifts: set('shifts'),
    setLoading: set('loading'),
    setError: set('error'),
    setManpowerCount: set('manpowerCount'),
    setManpowerList: set('manpowerList'),
    setLastRefreshedAt: set('lastRefreshedAt'),
  },
  actions: {
    fetchCells: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'cell',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setCells', records);
        return true;
      }
      return false;
    },

    fetchShifts: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'businesshours',
          query: '?sortquery=sortindex==1',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setShifts', records);
        return true;
      }
      return false;
    },

    fetchManpowerList: async ({ commit, dispatch, rootGetters }) => {
      const filters = rootGetters['webApp/filters'];
      const date = parseInt(filters.date.value.replace(/-/g, ''), 10);
      commit('setLoading', true);
      commit('setManpowerList', []);
      commit('setError', false);
      const data = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'manpower',
          query: `?query=date==${date}&sortquery=sortindex==1`,
        },
        { root: true },
      );
      if (data && data.results) {
        commit('setManpowerList', data.results);
        commit('setManpowerCount', data.totalCount);
        commit('setError', false);
        commit('setLastRefreshedAt', new Date().toLocaleTimeString('en-GB'));
      } else {
        commit('setManpowerList', []);
        commit('setManpowerCount', 0);
        commit('setError', true);
      }
      commit('setLoading', false);
    },

    createManpower: async ({ dispatch }, payload) => {
      const record = await dispatch(
        'element/postRecord',
        {
          elementName: 'manpower',
          payload,
        },
        { root: true },
      );
      // eslint-disable-next-line
      return record && record.id;
    },

    updateReason: async ({ dispatch, commit, state }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'manpower',
          id,
          payload,
        },
        { root: true },
      );
      if (updated) {
        let { manpowerList } = state;
        manpowerList = manpowerList.map((dt) => {
          // eslint-disable-next-line
          if (dt._id === id) {
            return {
              ...dt,
              ...payload,
            };
          }
          return dt;
        });
        commit('setManpowerList', []);
        commit('setManpowerList', manpowerList);
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'DOWNTIME_UPDATE',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'DOWNTIME_UPDATE',
        }, {
          root: true,
        });
      }
    },
  },
  getters: {
    cellList: ({ cells }) => {
      let cellList = [];
      if (cells && cells.length) {
        cellList = sortArray(cells, 'cellname').map(({ cellname }) => ({
          text: cellname,
          value: cellname,
        }));
      }
      return cellList;
    },

    shiftList: ({ shifts }) => {
      let shiftList = [];
      if (shifts && shifts.length) {
        const allShifts = shifts.filter((rec) => rec.type === 'shift');
        shiftList = [...new Set(allShifts.map((item) => ({
          ...item,
          text: item.name,
          value: item.name,
        })))];
      }
      return shiftList;
    },

    hourList: (_, { shiftList }, ___, rootGetters) => {
      const hours = [];
      const { shiftName, date } = rootGetters['webApp/filters'];
      const shiftVal = shiftName?.value;
      const dateVal = date?.value;
      if (shiftVal && dateVal) {
        const selectedShift = shiftList.find((s) => s.name === shiftVal);
        if (selectedShift) {
          const [year, month, day] = dateVal.split('-');
          const { starttime, endtime } = selectedShift;
          let startHour = parseInt(starttime.split(':')[0], 10);
          let endHour = parseInt(endtime.split(':')[0], 10);
          let diff = endHour - startHour;
          if (diff <= 0) {
            diff += 24;
          }
          let startMins = '00';
          let endMins = '30';
          let currentDay = day;
          for (let i = 1; i <= diff * 2; i += 1) {
            endHour = startHour;
            if (startHour === 24) {
              startHour = 0;
              currentDay = parseInt(currentDay, 10) + 1;
            }
            if (i % 2 === 0) {
              endHour = startHour + 1;
              startMins = '30';
              endMins = '00';
            } else {
              startMins = '00';
              endMins = '30';
            }
            if (endHour === 24) {
              endHour = 0;
            }
            const st = `0${startHour}`.slice(-2);
            const et = `0${endHour}`.slice(-2);
            hours.push({
              key: `${st}:${startMins} - ${et}:${endMins}`,
              timestamp: new Date(
                year,
                month - 1,
                currentDay,
                parseInt(st, 10),
                parseInt(startMins, 10),
                0,
              ).getTime(),
            });
            if (i % 2 === 0) {
              startHour += 1;
            }
          }
        }
      }
      return hours;
    },

    manpower: ({ manpowerList }, getters, rootState, rootGetters) => {
      let manpower = [];
      if (manpowerList && manpowerList.length) {
        manpower = rootGetters['webApp/filteredRecords'](manpowerList);
      }
      return manpower;
    },
  },
});
