import ApiService from '@shopworx/services/api/api.service';
import AuthService from '@shopworx/services/api/auth.service';
import IdapService from '@shopworx/services/api/idap.service';
import SessionService from '@shopworx/services/util/session.service';
import { set } from '@shopworx/services/util/store.helper';
import { onLogin, onLogout } from '../../infinityLoader';
import router from '../../router';

export default ({
  state: {
    loginType: 'INFINITY',
    sessionId: null,
  },
  mutations: {
    setSessionId: set('sessionId'),
  },
  actions: {
    initAuth: async ({ state, commit, dispatch }, to = {}) => {
      const { loginType } = state;
      const sessionId = SessionService.getSession();
      commit('setSessionId', sessionId);
      ApiService.setLoginTypeHeader(loginType);
      ApiService.setSessionHeader(sessionId);
      /** IDAP login integration start */
      const src = to?.query?.src_url;
      if (typeof src === 'string' && src.toLowerCase() === 'idap') {
        const token = SessionService.getIdapToken();
        try {
          const { data } = await IdapService.isLoggedIn(token);
          if (data && data.email) {
            const payload = { identifier: data.email, password: data.email };
            const success = await dispatch('authenticate', payload);
            if (success) {
              return true;
            }
          }
          router.replace({
            name: 'login',
            query: { redirect: to.fullPath },
          });
        } catch (e) {
          router.replace({
            name: 'login',
            query: { redirect: to.fullPath },
          });
        }
      }
      return false;
      /** IDAP login integration end */
    },

    authenticate: async ({
      state,
      commit,
      dispatch,
      rootGetters,
    }, payload) => {
      try {
        const { loginType } = state;
        const isWebView = rootGetters['helper/isWebView'];
        const isIos = rootGetters['helper/isIos'];
        ApiService.setLoginTypeHeader(loginType);
        const { data } = await AuthService.authenticate(payload);
        if (data && data.sessionId) {
          commit('setSessionId', data.sessionId);
          SessionService.setSession(data.sessionId);
          ApiService.setSessionHeader(data.sessionId);
          const success = await dispatch('user/getMe', null, { root: true });
          if (success) {
            if (isWebView || isIos) {
              if (isIos) {
                try {
                  window.webkit.messageHandlers.onLogin.postMessage({
                    sessionId: data.sessionId,
                    ...payload,
                  });
                } catch (err) {
                  console.log('The iOS native context does not exist yet');
                }
              } else {
                onLogin({
                  sessionId: data.sessionId,
                  ...payload,
                });
                // onLogin(data.sessionId);
              }
            }
            return true;
          }
          commit('setSessionId', null);
          SessionService.removeSession();
          ApiService.removeHeader();
          if (isIos) {
            try {
              window.webkit.messageHandlers.onLogin.postMessage({
                sessionId: null,
                identifier: null,
                password: null,
              });
            } catch (err) {
              console.log('The iOS native context does not exist yet');
            }
          } else {
            onLogin({
              sessionId: null,
              identifier: null,
              password: null,
            });
            // onLogin(null);
          }
        } else if (data && data.errors) {
          commit('helper/setAlert', {
            show: true,
            type: 'error',
            message: data.errors.errorCode,
          }, {
            root: true,
          });
        }
      } catch (e) {
        return false;
      }
      return false;
    },

    authenticateWithOtp: async ({
      state,
      commit,
      dispatch,
      rootGetters,
    }, payload) => {
      try {
        const { loginType } = state;
        const isWebView = rootGetters['helper/isWebView'];
        const isIos = rootGetters['helper/isIos'];
        ApiService.setLoginTypeHeader(loginType);
        const { data } = await AuthService.authenticateWithOtp(payload);
        if (data && data.sessionId) {
          commit('setSessionId', data.sessionId);
          SessionService.setSession(data.sessionId);
          ApiService.setSessionHeader(data.sessionId);
          const success = await dispatch('user/getMe', null, { root: true });
          if (success) {
            if (isWebView || isIos) {
              if (isIos) {
                try {
                  window.webkit.messageHandlers.onLogin.postMessage({
                    sessionId: data.sessionId,
                    loginMethod: 'OTP',
                    ...payload,
                  });
                } catch (err) {
                  console.log('The iOS native context does not exist yet');
                }
              } else {
                onLogin({
                  sessionId: data.sessionId,
                  loginMethod: 'OTP',
                  ...payload,
                });
                // onLogin(data.sessionId);
              }
            }
            return true;
          }
          commit('setSessionId', null);
          SessionService.removeSession();
          ApiService.removeHeader();
          if (isIos) {
            try {
              window.webkit.messageHandlers.onLogin.postMessage({
                sessionId: null,
                identifier: null,
                password: null,
                loginMethod: 'OTP',
              });
            } catch (err) {
              console.log('The iOS native context does not exist yet');
            }
          } else {
            onLogin({
              sessionId: null,
              identifier: null,
              password: null,
              loginMethod: 'OTP',
            });
            // onLogin(null);
          }
        } else if (data && data.errors) {
          commit('helper/setAlert', {
            show: true,
            type: 'error',
            message: data.errors.errorCode,
          }, {
            root: true,
          });
        }
      } catch (e) {
        return false;
      }
      return false;
    },

    logoutUser: async ({ commit, rootState, rootGetters }) => {
      try {
        const isWebView = rootGetters['helper/isWebView'];
        const isIos = rootGetters['helper/isIos'];
        const { data } = await AuthService.logout();
        if (data && data.results) {
          const { storageLocation } = rootState.webApp;
          SessionService.removeSession();
          commit('setSessionId', null);
          commit('user/setMe', null, { root: true });
          commit('user/setMySolutions', [], { root: true });
          commit('webApp/resetConfig', null, { root: true });
          Object.keys(storageLocation).forEach((loc) => {
            localStorage.removeItem(storageLocation[loc]);
          });
          if (isWebView || isIos) {
            if (isIos) {
              try {
                window.webkit.messageHandlers.onLogout.postMessage({ clearCredentials: true });
              } catch (err) {
                console.log('The iOS native context does not exist yet while logging out');
              }
            } else {
              onLogout();
            }
          }
        } else if (data && data.errors) {
          commit('helper/setAlert', {
            show: true,
            type: 'error',
            message: data.errors.errorCode,
          }, {
            root: true,
          });
          return false;
        }
      } catch (e) {
        return false;
      }
      return true;
    },

    generateOtp: async ({ commit }, payload) => {
      try {
        const { data } = await AuthService.generateOtp(payload);
        if (data && data.errors) {
          commit('helper/setAlert', {
            show: true,
            type: 'error',
            message: data.errors.errorCode,
          }, {
            root: true,
          });
          return false;
        }
      } catch (e) {
        return false;
      }
      return true;
    },

    resetPassword: async ({ commit }, payload) => {
      try {
        const { data } = await AuthService.resetPassword(payload);
        if (data && data.errors) {
          commit('helper/setAlert', {
            show: true,
            type: 'error',
            message: data.errors.errorCode,
          }, {
            root: true,
          });
          return false;
        }
      } catch (e) {
        return false;
      }
      return true;
    },
  },
  getters: {
    isLoggedIn: ({ sessionId }) => !!sessionId,
  },
});
