/* eslint-disable */
/*
 infinityloader.js
 @author: ashinde@entrib.com
 Use this as API to interact with ShopWorx Infinity - Android App
*/

// APIs to interact with

// Set session id, identifier and password on login
export const onLogin = ({
  sessionId,
  identifier,
  password,
  loginMethod = 'PASSWORD',
  remember = true,
}) => {
  App.onLogin(
    sessionId,
    identifier,
    password,
    loginMethod,
    remember,
  );
};

/* // Set session id on login
export const onLogin = (sessionId) => {
  App.onLogin(sessionId);
}; */

// Remove session id on logout
export const onLogout = () => {
  App.onLogout();
};
