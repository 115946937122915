import { set } from '@shopworx/services/util/store.helper';

export default ({
  namespaced: true,
  state: {
    loading: false,
    traceabilityData: null,
  },
  mutations: {
    setLoading: set('loading'),
    setTraceabilityData: set('traceabilityData'),
  },
  actions: {
    fetchTraceabilityData: async ({
      dispatch,
      commit,
    }, productCode) => {
      commit('setLoading', true);
      commit('setTraceabilityData', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'traceability',
          query: `?query=productcode=="${productCode}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        const [traceabilityData] = records;
        commit('setTraceabilityData', traceabilityData);
      }
      commit('setLoading', false);
    },
  },
  getters: {
  },
});
