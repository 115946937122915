import { set, toggle } from '@shopworx/services/util/store.helper';

function checkPos(value) {
  return (Number.isNaN(Number(value)) || Number(value) < 0);
}
function checkInt(value) {
  return (Number.isNaN(Number(value)) || !Number.isInteger(Number(value)));
}
function checkBit(value) {
  return (Number.isNaN(Number(value)) || Number(value) > 7);
}
function checkDescript(value) {
  return (!value || String(value).length > 200);
}
function checkUnit(value) {
  return (!value || String(value).length > 5);
}
function checkName(value) {
  const re = /^[a-zA-Z0-9-_]+$/;
  return (!value || String(value).length > 50 || !re.test(String(value)));
}
function checkPlcParam(value) {
  const re = /^(?!\.)(?!.*\.$)(?!.*?\.\.)([a-zA-Z]+)([a-zA-Z0-9_.]+$)/;
  return !value || !re.test(String(value));
}
function pickExistingKeys(object, keys) {
  const pick = (obj, ...findKeys) => Object.fromEntries(
    findKeys
      .filter((key) => key in obj)
      .map((key) => [key, obj[key]]),
  );
  return pick(object, ...keys);
}

export default {
  namespaced: true,
  state: {
    addParameterDialog: false,
    parameterList: [],
    categoryList: [],
    datatypeList: [],
    lineList: [],
    sublineList: [],
    stationList: [],
    substationList: [],
    lineValue: '',
    sublineValue: '',
    stationValue: '',
    substationValue: '',
    filter: false,
    isApply: false,
    dataTypeList: [],
    categoryDataList: [],
    selectedParameterName: '',
    selectedParameterCategory: '',
    selectedParameterDatatype: '',
    subStationElementDeatils: [],
    createElementResponse: [],
    protocol: '',
    validationsPassed: false,
    // missingInformation: [],
    incorrectProtocol: [],
    invalidName: [],
    invalidDescript: [],
    invalidChineseDescript: [],
    invalidParameterCategory: [],
    invalidDataType: [],
    invalidMultiplicationFactor: [],
    invalidParameterUnit: [],
    invalidMaxDecimal: [],
    invalidPaid: [],
    invalidPlcParam: [],
    invalidBit: [],
    invalidNodeId: [],
    invalidStartAddress: [],
    invalidSize: [],
    invalidDBAddress: [],
    invalidEndpointUrl: [],
    invalidPlcIpAddr: [],
    invalidSequence: [],
    duplicateCombination: [],
    duplicateNames: [],
    duplicateNameFromDB: [],
    duplicateCombinationinList: [],
    parametersToUpload: [],
    protocolTags: [],
    assetId: 0,
    // reqProtocolTags: [],
  },
  mutations: {
    setProtocol: set('protocol'),
    toggleFilter: toggle('filter'),
    setFilter: set('filter'),
    setApply: set('isApply'),
    setAddParameterDialog: set('addParameterDialog'),
    setParameterList: set('parameterList'),
    setCategoryList: set('categoryList'),
    setDatatypeList: set('datatypeList'),
    setLineList: set('lineList'),
    setSublineList: set('sublineList'),
    setStationList: set('stationList'),
    setSubstationList: set('substationList'),
    setLineValue: set('lineValue'),
    setSublineValue: set('sublineValue'),
    setStationValue: set('stationValue'),
    setSubstationValue: set('substationValue'),
    setDataTypes: set('dataTypeList'),
    setCategoryData: set('categoryDataList'),
    setSelectedParameterName: set('selectedParameterName'),
    setSelectedParameterCategory: set('selectedParameterCategory'),
    setSelectedParameterDatatype: set('selectedParameterDatatype'),
    setSubStationIdDetails: set('subStationElementDeatils'),
    setCreateElementResponse: set('createElementResponse'),
    setCreateParam: set('validationsPassed'),
    // setMissingInformation: set('missingInformation'),
    setIncorrectProtocol: set('incorrectProtocol'),
    setInvalidName: set('invalidName'),
    setInvalidDescript: set('invalidDescript'),
    setInvalidChineseDescript: set('invalidChineseDescript'),
    setInvalidParameterCategory: set('invalidParameterCategory'),
    setInvalidDataType: set('invalidDataType'),
    setInvalidMultiplicationFactor: set('invalidMultiplicationFactor'),
    setInvalidParameterUnit: set('invalidParameterUnit'),
    setInvalidMaxDecimal: set('invalidMaxDecimal'),
    setInvalidPaid: set('invalidPaid'),
    setInvalidPlcParam: set('invalidPlcParam'),
    setInvalidBit: set('invalidBit'),
    setInvalidNodeId: set('invalidNodeId'),
    setInvalidStartAddress: set('invalidStartAddress'),
    setInvalidSize: set('invalidSize'),
    setInvalidDBAddress: set('invalidDBAddress'),
    setInvalidEndpointUrl: set('invalidEndpointUrl'),
    setInvalidPlcIpAddr: set('invalidPlcIpAddr'),
    setInvalidSequence: set('invalidSequence'),
    setDuplicateCombination: set('duplicateCombination'),
    setDuplicateNames: set('duplicateNames'),
    setDuplicateNameFromDB: set('duplicateNameFromDB'),
    setDuplicateCombinationinList: set('duplicateCombinationinList'),
    setParametersToUpload: set('parametersToUpload'),
    setProtocolTags: set('protocolTags'),
    setAssetId: set('assetId'),
    // setReqProtocolTags: set('reqProtocolTags'),
  },
  actions: {
    getAssets: async ({ commit, rootGetters }) => {
      const licensedAssets = rootGetters['user/licensedAssets'];
      if (licensedAssets && licensedAssets.length) {
        commit('setAssetId', licensedAssets[0]);
        return true;
      }
      return false;
    },
    createElement: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/createElement',
        { element: payload },
        { root: true },
      );
      return created;
    },
    createTags: async ({ dispatch }, payload) => {
      const element = await dispatch(
        'element/createElementTags',
        payload,
        { root: true },
      );
      if (element) {
        // commit('setCreateElementResponse', element);
        return true;
      }
      return false;
    },
    getElementDetails: async ({ dispatch }, elementName) => {
      const element = await dispatch(
        'element/getElement',
        elementName,
        { root: true },
      );
      return element;
    },
    getSubStationIdElement: async ({ dispatch }, elementName) => {
      const element = await dispatch(
        'element/getElement',
        elementName,
        { root: true },
      );
      if (element) {
        // commit('setSubStationIdDetails', element);
        return element;
      }
      return false;
    },
    updateTagStatus: async ({ dispatch }, request) => {
      const putTagstatus = await dispatch(
        'element/updateTagById',
        {
          payload: request,
        },
        { root: true },
      );
      return putTagstatus;
    },
    getPageDataList: async ({ commit, dispatch }) => {
      const categoryList = await dispatch(
        'element/getRecords',
        { elementName: 'category' },
        { root: true },
      );
      const datatypeList = await dispatch(
        'element/getRecords',
        { elementName: 'datatypes' },
        { root: true },
      );
      const lineList = await dispatch(
        'element/getRecords',
        { elementName: 'line' },
        { root: true },
      );
      if (categoryList && categoryList.length) {
        commit('setCategoryList', categoryList);
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'NO_PARAMETER_CATEGORY_MASTER',
        }, {
          root: true,
        });
      }
      if (datatypeList && datatypeList.length) {
        commit('setDatatypeList', datatypeList);
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'NO_DATA_TYPE_MASTER',
        }, {
          root: true,
        });
      }
      if (lineList) {
        commit('setLineList', lineList);
      }
    },
    getDataTypes: async ({ commit, dispatch }, query) => {
      const list = await dispatch(
        'element/getRecords',
        { elementName: 'datatypes', query },
        { root: true },
      );
      let datatypes = [];
      if (list && list.length) {
        datatypes = list.map((l) => ({
          ...l,
          plc: 'Siemens',
          protocol: 'SNAP7',
        }));
      }
      commit('setDataTypes', datatypes);
    },
    getCategory: async ({ commit, dispatch }, query) => {
      const list = await dispatch(
        'element/getRecords',
        { elementName: 'category', query },
        { root: true },
      );
      let category = [];
      if (list && list.length) {
        category = list.map((l) => ({
          ...l,
          plc: 'Siemens',
          protocol: 'SNAP7',
        }));
      }
      commit('setCategoryData', category);
    },
    addDataType: async ({ dispatch }, payload) => {
      const list = await dispatch(
        'element/postRecord',
        {
          elementName: 'datatypes',
          payload,
        },
        { root: true },
      );
      if (list) {
        dispatch('getDataTypes');
        return true;
      }
      return false;
    },
    addCategory: async ({ dispatch }, payload) => {
      const list = await dispatch(
        'element/postRecord',
        {
          elementName: 'category',
          payload,
        },
        { root: true },
      );
      if (list) {
        dispatch('getCategory');
        return true;
      }
      return false;
    },
    getLineList: async ({ commit, dispatch }, query) => {
      const lineList = await dispatch(
        'element/getRecords',
        { elementName: 'line', query },
        { root: true },
      );
      if (lineList) {
        commit('setLineList', lineList);
      }
      return lineList;
    },
    getSublineList: async ({ commit, dispatch }, query) => {
      const sublineList = await dispatch(
        'element/getRecords',
        { elementName: 'subline', query },
        { root: true },
      );
      if (sublineList) {
        commit('setSublineList', sublineList);
      }
      return sublineList;
    },
    getStationList: async ({ commit, dispatch }, query) => {
      const stationList = await dispatch(
        'element/getRecords',
        { elementName: 'station', query },
        { root: true },
      );
      if (stationList) {
        commit('setStationList', stationList);
      }
      return stationList;
    },
    getSubstationList: async ({ commit, dispatch }, query) => {
      const substationList = await dispatch(
        'element/getRecords',
        { elementName: 'substation', query },
        { root: true },
      );
      if (substationList) {
        commit('setSubstationList', substationList);
      }
      return substationList;
    },
    createParameter: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'parameters',
          payload,
        },
        { root: true },
      );
      return created;
    },
    createParameterList: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postBulkRecords',
        {
          elementName: 'parameters',
          payload,
        },
        { root: true },
      );
      return created;
    },
    getParameterListRecords: async ({ dispatch, commit }, query, socketData) => {
      const lineList = await dispatch(
        'element/getRecords',
        { elementName: 'line' },
        { root: true },
      );
      const subLineList = await dispatch(
        'element/getRecords',
        { elementName: 'subline' },
        { root: true },
      );
      const stationList = await dispatch(
        'element/getRecords',
        { elementName: 'station' },
        { root: true },
      );
      const subStationList = await dispatch(
        'element/getRecords',
        { elementName: 'substation' },
        { root: true },
      );
      const parameterList = await dispatch(
        'element/getRecords',
        {
          elementName: 'parameters',
          query,
        },
        { root: true },
      );
      parameterList.forEach(async (item, key) => {
        item.number = key + 1;
        item.datatype = Number(item.datatype);
        if (lineList.length) {
          item.line = lineList.filter((line) => Number(line.id) === Number(item.lineid))[0].name;
        }
        if (subLineList.length) {
          item.subline = subLineList
            .filter((subline) => subline.id === item.sublineid)[0].name;
        }
        if (stationList.length) {
          item.station = stationList
            .filter((station) => station.id === item.stationid)[0].name;
        }
        if (subStationList.length) {
          item.substation = subStationList
            .filter((substation) => substation.id === item.substationid)[0].name;
        }
        item.parametercategory = Number(item.parametercategory);
        if (socketData && item.name === socketData[item.name]) {
          item.monitorvalue = socketData.monitorvalue;
        }
      });
      commit('setParameterList', parameterList);
      return parameterList;
    },
    updateParameter: async ({ dispatch }, postData) => {
      const { id, payload } = postData;
      const putParameter = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'parameters',
          id,
          payload,
        },
        { root: true },
      );
      return putParameter;
    },
    deleteParameter: async ({ dispatch }, id) => {
      const deleteParameter = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'parameters',
          queryParam: `?query=id=="${id}"`,
        },
        { root: true },
      );
      return deleteParameter;
    },
    deleteDatatype: async ({ dispatch }, id) => {
      const deleteParameter = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'datatypes',
          queryParam: `?query=id==${id}`,
        },
        { root: true },
      );
      return deleteParameter;
    },
    deleteCategory: async ({ dispatch }, id) => {
      const deleteParameter = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'category',
          queryParam: `?query=id==${id}`,
        },
        { root: true },
      );
      return deleteParameter;
    },
    updateDataType: async ({ dispatch }, payload) => {
      const putParameter = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'datatypes',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      return putParameter;
    },
    updateCategory: async ({ dispatch }, payload) => {
      const putParameter = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'category',
          queryParam: payload.query,
          payload: payload.payload,
        },
        { root: true },
      );
      return putParameter;
    },
    downloadToPLC: async ({ dispatch }, payload) => {
      const orders = await dispatch(
        'element/postSocket',
        {
          functionName: 'parameterupload',
          payload,
        },
        { root: true },
      );
      return orders;
    },
    getProtocolTags: async ({ commit, dispatch, state }, { protocolList, comOptTags }) => {
      const tags = [];
      const { protocol } = state;
      const elementDetails = await dispatch('getElementDetails', 'parameters');
      // Get required tags
      tags.push(
        ...elementDetails.tags.filter((tag) => tag.required),
      );
      // Get protocol specific tags
      const protoSpecTags = [
        ...elementDetails.tags.filter((tag) => protocolList
          .filter((prot) => prot.name === protocol)[0]
          .tagNames.includes(tag.tagName)),
      ];
      tags.push(...protoSpecTags);
      // await commit('setReqProtocolTags', [...tags]);
      // Get common optional tags
      tags.push(
        ...elementDetails.tags.filter((tag) => comOptTags.includes(tag.tagName)),
      );
      commit('setProtocolTags', [...tags]);
    },
    validateParameter: ({ commit, state }, uploadParameters) => {
      const {
        lineValue,
        sublineValue,
        stationValue,
        substationValue,
        datatypeList,
        categoryList,
        protocol,
        stationList,
        parameterList,
        protocolTags,
        assetId,
        // reqProtocolTags,
      } = state;
      // const missingInformation = [];
      const incorrectProtocol = [];
      const invalidName = [];
      const invalidDescript = [];
      const invalidChineseDescript = [];
      const invalidParameterCategory = [];
      const invalidDataType = [];
      const invalidMultiplicationFactor = [];
      const invalidParameterUnit = [];
      const invalidPaid = [];
      const invalidMaxDecimal = [];
      const invalidPlcParam = [];
      const invalidBit = [];
      const invalidNodeId = [];
      const invalidStartAddress = [];
      const invalidSize = [];
      const invalidDBAddress = [];
      const invalidEndpointUrl = [];
      const invalidPlcIpAddr = [];
      const invalidSequence = [];
      const duplicateCombination = [];
      const duplicateNames = [];
      const duplicateNameFromDB = [];
      const duplicateCombinationinList = [];
      const protocolTagsNames = protocolTags.map((tag) => tag.tagName);
      const reqProtocolTagsNames = protocolTags.filter((tag) => tag.required)
        .map((tag) => tag.tagName);
      uploadParameters.forEach((item, index) => {
        /* TO-DO: Drop additional tags */
        Object.keys(item).forEach((key) => (!protocolTagsNames.includes(key)
          ? delete item[key] : {}));
        /* Add information from UI */
        item.lineid = lineValue;
        item.sublineid = sublineValue;
        item.stationid = stationValue;
        item.substationid = substationValue;
        item.protocol = item.protocol.toUpperCase();
        item.name = item.name.toLowerCase();
        item.assetid = assetId;
        /* Check and add: Endpoint URL & PLC-IP Address */
        const selectedStationDetails = stationList
          .filter((station) => stationValue === station.id)[0];
        if (item.protocol === 'OPCUA') {
          if (selectedStationDetails.endpointurl) {
            item.endpointurl = selectedStationDetails.endpointurl;
          } else {
            invalidEndpointUrl.push({
              row: index + 2,
              endpointurl: item.endpointurl,
            });
          }
        } else if (selectedStationDetails.plcipaddress) {
          item.plcaddress = selectedStationDetails.plcipaddress;
        } else {
          invalidPlcIpAddr.push({
            row: index + 2,
            plcipaddress: item.plcipaddress,
          });
        }
        /* TO-DO: Check if required tags are present
        (situation: someone deletes a column in CSV) */
        /* Check: Required keys present */
        // const checker = (arr, target) => target.every((v) => arr.includes(v));
        // const keys = Object.keys(item);
        // console.log(checker(keys, reqProtocolTagsNames));
        // if (!checker(keys, reqProtocolTagsNames)) {
        //   missingInformation.push({
        //     row: index + 2,
        //     name: item.name,
        //   });
        // }
        /* Check: Protocol */
        if (item.protocol !== protocol) {
          incorrectProtocol.push({ row: index + 2, protocol: item.protocol });
        }
        /* Check: Name */
        if (checkName(item.name)) {
          invalidName.push({
            row: index + 2,
            name: item.name,
          });
        }
        /* Check: Description */
        if (('description' in item) && item.description) {
          if (checkDescript(item.description)) {
            invalidDescript.push({
              row: index + 2,
              description: item.description,
            });
          }
        }
        /* Check Chinese Description */
        if (('chinesedescription' in item) && item.chinesedescription) {
          if (checkDescript(item.chinesedescription)) {
            invalidChineseDescript.push({
              row: index + 2,
              chinesedescription: item.chinesedescription,
            });
          }
        }
        /* Check: Parameter Category */
        const categoryIdList = categoryList.map((datatype) => Number(datatype.id));
        if (!categoryIdList.includes(Number(item.parametercategory))) {
          invalidParameterCategory.push({
            row: index + 2,
            parametercategory: item.parametercategory,
          });
        }
        /* Check: Datatype */
        const dataTypeIdList = datatypeList.map((datatype) => Number(datatype.id));
        if (!dataTypeIdList.includes(Number(item.datatype))) {
          invalidDataType.push({
            row: index + 2,
            datatype: item.datatype,
          });
        } else if (item.protocol === 'SNAP7' || item.protocol === 'MODBUS') {
          const dataTypeDetails = datatypeList
            .filter((datatype) => Number(datatype.id) === Number(item.datatype));
          item.isbigendian = Boolean(dataTypeDetails[0].isbigendian);
          item.isswapped = Boolean(dataTypeDetails[0].isswapped);
        }
        /* Check: PA-ID */
        if (checkPos(item.paid) || checkInt(item.paid)) {
          invalidPaid.push({
            row: index + 2,
            paid: item.paid,
          });
        }
        /* Check: Multiplication Factor */
        if (('multiplicationfactor' in item) && item.multiplicationfactor) {
          if (checkPos(item.multiplicationfactor)) {
            invalidMultiplicationFactor.push({
              row: index + 2,
              multiplicationfactor: item.multiplicationfactor,
            });
          }
        } else {
          item.multiplicationfactor = 1;
        }
        /* Check: Parameter Unit */
        if (('parameterunit' in item) && item.parameterunit) {
          if (checkUnit(item.parameterunit)) {
            invalidParameterUnit.push({
              row: index + 2,
              parameterunit: item.parameterunit,
            });
          }
        }
        /* Check: Max Decimal (Pass default value for NaN) */
        if (('maxdecimal' in item) && item.maxdecimal) {
          if (checkPos(item.maxdecimal) || checkInt(item.maxdecimal)) {
            invalidMaxDecimal.push({
              row: index + 2,
              maxdecimal: item.maxdecimal,
            });
          }
        } else {
          item.maxdecimal = 3;
        }
        /* Check: Sequence */
        /* TO-DO: Remove sequence logic entirely */
        if (('sequence' in item) && item.sequence) {
          if (checkPos(item.sequence) || checkInt(item.sequence)) {
            invalidSequence.push({
              row: index + 2,
              sequence: item.sequence,
            });
          }
          item.sequence = Number(item.sequence);
        }
        /* Following: Protocol Specific */
        /* Check: Size */
        const dataTypeDetails = datatypeList
          .filter((datatype) => Number(datatype.id) === Number(item.datatype))[0];
        if (('size' in item) && item.size) {
          if (protocol !== 'SQL' && Number(item.datatype) !== 11) {
            if (dataTypeDetails && dataTypeDetails.size !== Number(item.size)) {
              invalidSize.push({
                row: index + 2,
                size: item.size,
              });
            }
          }
        } else {
          item.size = dataTypeDetails.size;
        }
        /* Check: PLC Parameter */
        if ('plc_parameter' in item) {
          if (checkPlcParam(item.plc_parameter)) {
            invalidPlcParam.push({
              row: index + 2,
              plc_parameter: item.plc_parameter,
            });
          }
        }
        /* Check: Bitnumber */
        if ('bitnumber' in item) {
          if (checkPos(item.bitnumber) || checkInt(item.bitnumber)
          || checkBit(item.bitnumber)) {
            invalidBit.push({
              row: index + 2,
              bitNumber: item.bitnumber,
            });
          }
        }
        /* Check: Node ID */
        // if ('nodeid' in item) {
        //   if (checkNodeId(item.nodeid)) {
        //     invalidNodeId.push({
        //       row: index + 2,
        //       nodeid: item.nodeid,
        //     });
        //   }
        // }
        /* Check: Startaddress */
        if ('startaddress' in item) {
          if (checkPos(item.startaddress) || checkInt(item.startaddress)) {
            invalidStartAddress.push({
              row: index + 2,
              startaddress: item.startaddress,
            });
          }
        }
        /* Check:  DB Address */
        if ('dbaddress' in item) {
          if (checkPos(item.dbaddress) || checkInt(item.dbaddress)) {
            invalidDBAddress.push({
              row: index + 2,
              dbaddress: item.dbaddress,
            });
          }
        }
        /* Drop all null/undefined and non-required fields */
        Object.keys(item).forEach((key) => (!item[key]
          && !reqProtocolTagsNames.includes(key) ? delete item[key] : {}));
      });
      /* Following: Duplicate Check */
      /* Check: Duplicate Names within substation */
      const parameterNames = uploadParameters.map((item) => item.name);
      duplicateNames.push(...parameterNames.map((item) => item)
        .filter((value, index, self) => self.indexOf(value) !== index));
      duplicateNameFromDB.push(...parameterList
        .filter((pl) => parameterNames.includes(pl.name)).map((duplicate) => duplicate.name));
      /* Check: Duplicate Address Combination */
      const paramFiltered = uploadParameters.map((item) => (
        pickExistingKeys(item, ['dbaddress', 'startaddress', 'bitnumber', 'datatype', 'nodeid'])
      ));
      duplicateCombinationinList.push(...paramFiltered.filter(
        (v, i, a) => a.findIndex(
          (t) => (JSON.stringify(v) === JSON.stringify(t)) !== i,
        ),
      ));
      uploadParameters.forEach((l, index1) => {
        uploadParameters.forEach((d, index2) => {
          if ((JSON.stringify(l) === JSON.stringify(d)) && (index1 !== index2)) {
            const tempObj = { ...d };
            tempObj.row = index1 + 2;
            duplicateCombination.push(tempObj);
          }
        });
      });
      // commit('setMissingInformation', missingInformation);
      commit('setIncorrectProtocol', incorrectProtocol);
      commit('setInvalidName', invalidName);
      commit('setInvalidDescript', invalidDescript);
      commit('setInvalidChineseDescript', invalidChineseDescript);
      commit('setInvalidParameterCategory', invalidParameterCategory);
      commit('setInvalidDataType', invalidDataType);
      commit('setInvalidMultiplicationFactor', invalidMultiplicationFactor);
      commit('setInvalidParameterUnit', invalidParameterUnit);
      commit('setInvalidPaid', invalidPaid);
      commit('setInvalidMaxDecimal', invalidMaxDecimal);
      commit('setInvalidPlcParam', invalidPlcParam);
      commit('setInvalidBit', invalidBit);
      commit('setInvalidNodeId', invalidNodeId);
      commit('setInvalidStartAddress', invalidStartAddress);
      commit('setInvalidSize', invalidSize);
      commit('setInvalidDBAddress', invalidDBAddress);
      commit('setInvalidEndpointUrl', invalidEndpointUrl);
      commit('setInvalidPlcIpAddr', invalidPlcIpAddr);
      commit('setInvalidSequence', invalidSequence);
      commit('setDuplicateNames', duplicateNames);
      commit('setDuplicateNameFromDB', duplicateNameFromDB);
      commit('setDuplicateCombinationinList', duplicateCombinationinList);
      commit('setDuplicateCombination', duplicateCombination);
      commit('setParametersToUpload', uploadParameters);
    },
    resetUploadData({ commit }) {
      // commit('setMissingInformation', []);
      commit('setIncorrectProtocol', []);
      commit('setInvalidName', []);
      commit('setInvalidDescript', []);
      commit('setInvalidChineseDescript', []);
      commit('setInvalidParameterCategory', []);
      commit('setInvalidDataType', []);
      commit('setInvalidMultiplicationFactor', []);
      commit('setInvalidParameterUnit', []);
      commit('setInvalidPaid', []);
      commit('setInvalidMaxDecimal', []);
      commit('setInvalidPlcParam', []);
      commit('setInvalidBit', []);
      commit('setInvalidNodeId', []);
      commit('setInvalidStartAddress', []);
      commit('setInvalidSize', []);
      commit('setInvalidDBAddress', []);
      commit('setInvalidEndpointUrl', []);
      commit('setInvalidPlcIpAddr', []);
      commit('setInvalidSequence', []);
      commit('setDuplicateCombination', []);
      commit('setDuplicateNames', []);
      commit('setDuplicateNameFromDB', []);
      commit('setDuplicateCombinationinList', []);
      commit('setParametersToUpload', []);
    },
  },
};
