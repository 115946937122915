const routes = [
  {
    path: 'maintenance-task',
    component: () => import(/* webpackChunkName: "maintenance-task" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'maintenanceTask',
        component: () => import(/* webpackChunkName: "maintenance-task" */ './views/MaintenanceTask.vue'),
      },
      {
        path: 'task',
        name: 'addMaintenanceTask',
        component: () => import(/* webpackChunkName: "maintenance-task" */ './views/AddMaintenanceTask.vue'),
      },
      {
        path: 'task/:id',
        name: 'maintenanceTaskDetails',
        component: () => import(/* webpackChunkName: "maintenance-task" */ './views/MaintenanceTaskDetails.vue'),
        props: true,
      },
    ],
  },
];

export default routes;
