import {
  set, toggle, reactiveSetArray, reactiveRemoveArray,
} from '@shopworx/services/util/store.helper';
import { sortArray } from '@shopworx/services/util/sort.service';

export default ({
  namespaced: true,
  state: {
    calendarRef: null,
    calendarFocus: '',
    today: new Date().toISOString().substr(0, 10),
    drawer: false,
    view: 'default',
    assets: [],
    machines: [],
    solutions: [],
    taskList: [],
    loading: false,
    error: false,
    taskCount: 0,
    lastRefreshedAt: null,
    selectedTask: [],
    operators: [],
    taskChecklist: null,
    taskSpareparts: [],
  },
  mutations: {
    setCalendarRef: set('calendarRef'),
    setCalendarFocus: set('calendarFocus'),
    setDrawer: set('drawer'),
    toggleDrawer: toggle('drawer'),
    setView: set('view'),
    setAssets: set('assets'),
    setMachines: set('machines'),
    setSolutions: set('solutions'),
    setLoading: set('loading'),
    setError: set('error'),
    setTaskCount: set('taskCount'),
    setTaskList: set('taskList'),
    setTask: reactiveSetArray('taskList'),
    setTaskView: reactiveSetArray('selectedTask'),
    setLastRefreshedAt: set('lastRefreshedAt'),
    setSelectedTask: set('selectedTask'),
    setOperators: set('operators'),
    setTaskChecklist: set('taskChecklist'),
    setTaskSpareparts: set('taskSpareparts'),
    setSparepart: reactiveSetArray('taskSpareparts'),
    removeSparepart: reactiveRemoveArray('taskSpareparts'),
  },
  actions: {
    fetchAssets: async ({ commit, dispatch }) => {
      const assets = await dispatch(
        'industry/getAssets',
        null,
        { root: true },
      );
      if (assets && assets.length) {
        commit('setAssets', assets);
        return true;
      }
      return false;
    },

    fetchMachines: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'machine',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setMachines', records);
        return true;
      }
      return false;
    },

    fetchSolutions: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'solution',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSolutions', records);
        return true;
      }
      return false;
    },

    fetchOperators: async ({ commit, dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'operator',
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setOperators', records);
        return true;
      }
      return false;
    },

    fetchTaskList: async ({
      commit, dispatch, getters, rootGetters,
    }) => {
      const filters = rootGetters['webApp/filters'];
      let [min, max] = filters.date.value;
      [min, max] = getters.getDateRange([min, max]);
      commit('setLoading', true);
      commit('setTaskList', []);
      commit('setError', false);
      const completedTasks = `(acturalstarttime<${max}%26%26acturalendtime>=${min})`;
      const inProgressTasks = `(status=="in progress"%26%26acturalstarttime<${max})`;
      const newTasks = `(status=="new"%7C%7Cstatus=="assigned"%26%26planstarttime>=${min}%26%26planstarttime<${max})`;
      const or = '%7C%7C';
      const data = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'task',
          query: `?query=${completedTasks}${or}${inProgressTasks}${or}${newTasks}`,
        },
        { root: true },
      );
      if (data && data.results) {
        const tasks = data.results.map((task) => {
          const equipmentname = task.moldname || task.toolname;
          return {
            ...task,
            equipmentname,
          };
        });
        commit('setTaskList', tasks);
        commit('setTaskCount', data.totalCount);
        commit('setError', false);
        commit('setLastRefreshedAt', new Date().toLocaleTimeString('en-GB'));
      } else {
        commit('setTaskList', []);
        commit('setTaskCount', 0);
        commit('setError', true);
      }
      commit('setLoading', false);
    },

    fetchTask: async ({ commit, dispatch }, taskId) => {
      commit('setSelectedTask', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'task',
          query: `?query=id=="${taskId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setSelectedTask', records);
        return true;
      }
      commit('setSelectedTask', []);
      return false;
    },

    fetchNewlyAddedTask: async ({ dispatch }) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'task',
          query: '?query=planid=="plan-0"&sortquery=createdTimestamp==-1&pagenumber=1&pagesize=1',
        },
        { root: true },
      );
      return records;
    },

    createTask: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postRecord',
        {
          elementName: 'task',
          payload,
        },
        { root: true },
      );
      return created;
    },

    createTaskDetails: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postBulkRecords',
        {
          elementName: 'taskdetail',
          payload,
        },
        { root: true },
      );
      return created;
    },

    createTaskOperators: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postBulkRecords',
        {
          elementName: 'taskoperator',
          payload,
        },
        { root: true },
      );
      return created;
    },

    createRepairs: async ({ dispatch }, payload) => {
      const created = await dispatch(
        'element/postBulkRecords',
        {
          elementName: 'repair',
          payload,
        },
        { root: true },
      );
      return created;
    },

    updateTaskByTaskId: async ({
      state,
      commit,
      dispatch,
    }, { taskId, payload, listType }) => {
      const updated = await dispatch(
        'element/updateRecordByQuery',
        {
          elementName: 'task',
          queryParam: `?query=id=="${taskId}"`,
          payload,
        },
        { root: true },
      );
      if (listType === 'all') {
        const { taskList } = state;
        for (let i = 0; i < taskList.length; i += 1) {
          if (taskList[i].id === taskId) {
            commit('setTask', {
              index: i,
              payload: {
                ...taskList[i],
                ...payload,
              },
            });
          }
        }
      } else {
        const { selectedTask } = state;
        for (let i = 0; i < selectedTask.length; i += 1) {
          if (selectedTask[i].id === taskId) {
            commit('setTaskView', {
              index: i,
              payload: {
                ...selectedTask[i],
                ...payload,
              },
            });
          }
        }
      }
      if (updated) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'TASK_UPDATED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'TASK_UPDATED',
        }, {
          root: true,
        });
      }
    },

    deleteTaskByTaskId: async ({
      state,
      commit,
      dispatch,
    }, { taskId, listType }) => {
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'task',
          queryParam: `?query=id=="${taskId}"`,
        },
        { root: true },
      );
      if (listType === 'all') {
        const { taskList } = state;
        for (let i = 0; i < taskList.length; i += 1) {
          if (taskList[i].id === taskId) {
            commit('removeTask', {
              index: i,
            });
          }
        }
      }
      if (deleted) {
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'PLAN_DELETED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'PLAN_DELETED',
        }, {
          root: true,
        });
      }
      return deleted;
    },

    deleteTaskOperators: async ({ dispatch }, { taskId }) => {
      const deleted = await dispatch(
        'element/deleteRecordByQuery',
        {
          elementName: 'taskoperator',
          queryParam: `?query=taskid=="${taskId}"`,
        },
        { root: true },
      );
      return deleted;
    },

    updateTaskById: async ({ dispatch }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'task',
          id,
          payload,
        },
        { root: true },
      );
      return updated;
    },

    deleteTaskById: async ({ dispatch }, id) => {
      const deleted = await dispatch(
        'element/deleteRecordById',
        {
          elementName: 'task',
          id,
        },
        { root: true },
      );
      return deleted;
    },

    fetchTaskOperators: async ({ dispatch }, taskId) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'taskoperator',
          query: `?query=taskid=="${taskId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        return records;
      }
      return [];
    },

    fetchTaskChecklist: async ({ commit, dispatch }, taskId) => {
      commit('setTaskChecklist', null);
      const records = await dispatch(
        'element/getRecordsWithCount',
        {
          elementName: 'taskdetail',
          query: `?query=taskid=="${taskId}"`,
        },
        { root: true },
      );
      if (records) {
        commit('setTaskChecklist', records);
        return true;
      }
      return false;
    },

    updateTaskChecklistById: async ({ dispatch }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'taskdetail',
          id,
          payload,
        },
        { root: true },
      );
      return updated;
    },

    fetchTaskSpareparts: async ({ commit, dispatch }, taskId) => {
      commit('setTaskSpareparts', []);
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'replacement',
          query: `?query=taskid=="${taskId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        commit('setTaskSpareparts', records);
        return true;
      }
      return false;
    },

    fetchMachineSpareParts: async ({ dispatch }, machineId) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'sparepartbindmachineposition',
          query: `?query=machineid=="${machineId}"`,
        },
        { root: true },
      );
      if (records && records.length) {
        const parts = sortArray(records, 'sparepartname');
        return parts;
      }
      return [];
    },

    fetchSolutionDetails: async ({ dispatch }, solutionId) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'solutiondetail',
          query: `?query=solutionid=="${solutionId}"`,
        },
        { root: true },
      );
      return records;
    },

    fetchMachineOperators: async ({ dispatch }, machineId) => {
      const records = await dispatch(
        'element/getRecords',
        {
          elementName: 'operatorbindmachine',
          query: `?query=machineid=="${machineId}"`,
        },
        { root: true },
      );
      return records;
    },

    deleteSparepartById: async ({
      state,
      commit,
      dispatch,
    }, id) => {
      const deleted = await dispatch(
        'element/deleteRecordById',
        {
          elementName: 'replacement',
          id,
        },
        { root: true },
      );
      if (deleted) {
        const { taskSpareparts } = state;
        for (let i = 0; i < taskSpareparts.length; i += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if (taskSpareparts[i]._id === id) {
            commit('removeSparepart', {
              index: i,
            });
          }
        }
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'DELETED',
        }, {
          root: true,
        });
      } else {
        commit('helper/setAlert', {
          show: true,
          type: 'error',
          message: 'DELETED',
        }, {
          root: true,
        });
      }
      return deleted;
    },

    updateSparepartById: async ({
      state,
      commit,
      dispatch,
    }, { id, payload }) => {
      const updated = await dispatch(
        'element/updateRecordById',
        {
          elementName: 'replacement',
          id,
          payload,
        },
        { root: true },
      );
      if (updated) {
        const { taskSpareparts } = state;
        for (let i = 0; i < taskSpareparts.length; i += 1) {
          // eslint-disable-next-line no-underscore-dangle
          if (taskSpareparts[i]._id === id) {
            commit('setSparepart', {
              index: i,
              payload: {
                ...taskSpareparts[i],
                ...payload,
              },
            });
          }
        }
        commit('helper/setAlert', {
          show: true,
          type: 'success',
          message: 'UPDATED',
        }, {
          root: true,
        });
        return true;
      }
      commit('helper/setAlert', {
        show: true,
        type: 'error',
        message: 'UPDATED',
      }, {
        root: true,
      });
      return false;
    },

    addSparepart: async ({ state, commit, dispatch }, payload) => {
      const added = await dispatch(
        'element/postRecord',
        {
          elementName: 'replacement',
          payload,
        },
        { root: true },
      );
      if (added) {
        const { taskSpareparts } = state;
        const item = { ...payload, _id: added.id };
        commit('setTaskSpareparts', [
          item,
          ...taskSpareparts,
        ]);
      }
      return added;
    },
  },
  getters: {
    isCalendarView: ({ view }) => view !== 'default',

    selectedAsset: ({ assets }) => (assetId) => {
      let asset = null;
      if (assets && assets.length && assetId) {
        asset = assets.find((a) => a.id === assetId);
        if (asset) {
          asset = asset.assetName;
        }
      }
      return asset;
    },

    machineList: ({ machines }) => {
      let machineList = [];
      if (machines && machines.length) {
        machineList = sortArray(machines, 'machinename').map(({
          machinename,
          assetid,
          id,
          machinecode,
        }) => ({
          name: machinename,
          value: machinename,
          assetid,
          id,
          machinecode,
        }));
      }
      return machineList;
    },

    solutionList: ({ solutions }) => {
      let solutionList = [];
      if (solutions && solutions.length) {
        solutionList = sortArray(solutions, 'name').map(({
          name,
          id,
          type,
        }) => ({
          name,
          id,
          type,
        }));
      }
      return solutionList;
    },

    operatorList: ({ operators }) => {
      let operatorList = [];
      if (operators && operators.length) {
        operatorList = sortArray(operators, 'operatorname').map(({
          operatorname,
          id,
          operatorcode,
        }) => ({
          operatorid: id,
          operatorname,
          operatorcode,
        }));
      }
      return operatorList;
    },

    taskStatus: () => (status) => {
      let result = { text: 'notStarted', color: 'info' };
      if (status === 'in progress') {
        result = { text: 'inProgress', color: 'success' };
      } else if (status === 'completed') {
        result = { text: 'complete', color: 'accent' };
      } else if (status === 'assigned') {
        result = { text: 'assigned', color: 'warning' };
      }
      return result;
    },

    getDateRange: () => ([start, end]) => {
      const [sYear, sMonth, sDay] = start.split('-');
      const [eYear, eMonth, eDay] = end.split('-');
      const startDate = new Date(
        sYear,
        parseInt(sMonth, 10) - 1,
        sDay,
      );
      const endDate = new Date(
        eYear,
        parseInt(eMonth, 10) - 1,
        parseInt(eDay, 10) + 1,
      );
      return [
        startDate.getTime(),
        endDate.getTime(),
      ];
    },

    task: (
      { taskList },
      { isCalendarView },
      rootState,
      rootGetters,
    ) => {
      let task = null;
      if (taskList && taskList.length) {
        const [group] = rootGetters['webApp/group'];
        task = rootGetters['webApp/filteredRecords'](taskList);
        if (!isCalendarView) {
          task = rootGetters['webApp/sortedRecords'](task);
          task = sortArray(task, group);
          task = rootGetters['webApp/groupedRecords'](task);
        }
      }
      if (!task || !Object.keys(task).length) {
        task = null;
      }
      return task;
    },
  },
});
