const routes = [
  {
    path: 'receiving-production-vista',
    component: () => import(/* webpackChunkName: "receiving-production-vista" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'receivingProductionVista',
        component: () => import(/* webpackChunkName: "receiving-vista-home" */ './views/Home.vue'),
      },
      {
        path: 'create-new',
        name: 'create-new-receiving',
        component: () => import(/* webpackChunkName: "receiving-vista-create-new" */ './views/CreateNew.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit-receiving',
        component: () => import(/* webpackChunkName: "receiving-vista-create-new" */ './views/EditPlan.vue'),
      },
    ],
  },
];

export default routes;
