<template>
  <v-app-bar
    app
    flat
    :color="$vuetify.theme.dark ? '#121212' : 'white'"
  >
    <v-spacer></v-spacer>
    <img
      :src="`/assets/logo/${logoName}.png`"
      contain
      :alt="altText"
      height="52"
    />
    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AuthHeader',
  computed: {
    ...mapGetters('helper', ['primaryLogo', 'primaryText']),
    logoName() {
      return this.$vuetify.theme.dark
        ? `${this.primaryLogo.name}-dark`
        : `${this.primaryLogo.name}-light`;
    },
    altText() {
      return `${this.primaryLogo.name} logo`;
    },
  },
};
</script>
