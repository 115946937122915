import Vue from 'vue';

import './registerServiceWorker';

import App from './App.vue';

import router from './router';
import store from './store';
import i18n from './i18n';

import vuetify from './plugins/vuetify';
import './plugins/perfect-scrollbar';
import './plugins/vee-validate';
import './plugins/highcharts';
import './plugins/portal';

import './modules';

fetch(`/assets/config.json?t=${new Date().getTime()}`).then((res) => {
  res.json().then((d) => {
    const {
      icons,
      theme,
      ...config
    } = d;
    store.commit('helper/setAppConfig', config);
    new Vue({
      router,
      store,
      vuetify: vuetify(icons, theme),
      i18n,
      render: (h) => h(App),
    }).$mount('#app');
  });
});

Vue.config.productionTip = false;
