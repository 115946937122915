import { set } from '@shopworx/services/util/store.helper';
import ElementService from '@shopworx/services/api/element.service';

const getFormattedDate = (timestamp) => {
  const pad = (n, s = 2) => (`${new Array(s).fill(0)}${n}`).slice(-s);
  const d = new Date(timestamp);
  return `${pad(d.getFullYear(), 4)}-${pad(d.getMonth() + 1)}-${pad(d.getDate())} ${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;
};

export default ({
  namespaced: true,
  state: {
    elements: [],
    shifts: [],
    records: null,
    columns: [],
    totalCount: 0,
    fetching: false,
    downloadLogs: [],
    logCount: 0,
    fetchingLogs: false,
  },
  mutations: {
    setElements: set('elements'),
    setSift: set('shifts'),
    setRecords: set('records'),
    setColumns: set('columns'),
    setTotalCount: set('totalCount'),
    setFetching: set('fetching'),
    setDownloadLogs: set('downloadLogs'),
    setLogCount: set('logCount'),
    setFetchingLogs: set('fetchingLogs'),
  },
  actions: {
    getElements: async ({ commit, rootState, dispatch }) => {
      const { activeSite } = rootState.user;
      const elements = await dispatch(
        'element/getElementsBySite',
        activeSite,
        { root: true },
      );
      if (elements && elements.length) {
        const defaultElements = elements
          .filter((elem) => elem.element.elementType === 'DEFAULT');
        commit('setElements', defaultElements);
      }
    },
    getShift: async ({ commit, rootState, dispatch }) => {
      const { activeSite } = rootState.user;
      const elements = await dispatch(
        'element/getElementsBySite',
        activeSite,
        { root: true },
      );
      if (elements && elements.length) {
        const defaultElements = elements
          .filter((elem) => elem.element.elementType === 'DEFAULT');
        commit('setSift', defaultElements);
      }
    },

    getDownloadLog: async ({ rootState, commit }, { pageSize, pageNumber }) => {
      commit('setFetchingLogs', true);
      const { activeSite } = rootState.user;
      const { data } = await ElementService.getCollectionRecords(
        'datadownload',
        `?query=siteId==${activeSite}&pagenumber=${pageNumber}&pagesize=${pageSize}`,
      );
      if (data && data.results) {
        commit('setDownloadLogs', data.results);
        commit('setLogCount', data.totalCount);
      } else {
        commit('setDownloadLogs', []);
        commit('setLogCount', 0);
      }
      commit('setFetchingLogs', false);
    },

    createDownloadRequest: async ({ commit }, {
      dateFrom,
      dateTo,
      emailIds,
      elementName,
      fields,
    }) => {
      const queryParam = `?datefrom=${dateFrom}&dateto=${dateTo}`;
      const payload = {
        emailIds,
        elementName,
        fields,
      };
      try {
        const { data } = await ElementService.downloadData(queryParam, payload);
        if (data) {
          // await dispatch('getDownloadLog');
          commit('setFetchingLogs', false);
          return data;
        }
      } catch (e) {
        return false;
      }
      return false;
    },

    getRecords: async ({ dispatch, commit, state }, {
      elementName,
      shift,
      tags,
      columns,
      dateFrom,
      dateTo,
      pageNumber,
      pageSize,
    }) => {
      commit('setFetching', true);
      commit('setRecords', null);
      commit('setTotalCount', 0);
      commit('setColumns', columns);
      const { elements } = state;
      const queryParam = `?query=shiftName=="${shift}"&datefrom=${dateFrom}&dateto=${dateTo}&pagenumber=${pageNumber}&pagesize=${pageSize}`;
      const data = await dispatch(
        'element/getRecordsByTags',
        {
          elementName,
          queryParam,
          request: { tags },
        },
        { root: true },
      );
      if (data && data.results) {
        const selectedElem = elements.find((e) => e.element.elementName === elementName);
        const elemTags = selectedElem.tags.reduce((acc, cur) => {
          acc[cur.tagName] = cur.emgTagType;
          return acc;
        }, {});
        const records = data.results.map((r) => {
          const val = Object.keys(r).reduce((acc, cur) => {
            if (elemTags[cur] === 'Timestamp') {
              acc[cur] = getFormattedDate(r[cur]);
            } else {
              acc[cur] = r[cur];
            }
            return acc;
          }, {});
          return val;
        });
        commit('setRecords', data.results);
        commit('setTotalCount', data.totalCount);
        commit('setFetching', false);
        return {
          results: records,
          totalCount: data.totalCount,
        };
      }
      commit('setRecords', []);
      commit('setFetching', false);
      return false;
    },
  },
});
