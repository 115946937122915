const routes = [
  {
    path: 'production-planning-vista',
    component: () => import(/* webpackChunkName: "production-planning-vista" */ './views/Index.vue'),
    meta: {
      permissionRequired: true,
    },
    children: [
      {
        path: '',
        name: 'productionPlanningVista',
        component: () => import(/* webpackChunkName: "production-vista-home" */ './views/Home.vue'),
      },
      {
        path: 'create-new',
        name: 'create-new',
        component: () => import(/* webpackChunkName: "vista-create-new" */ './views/CreateNew.vue'),
      },
      {
        path: 'edit/:id',
        name: 'edit',
        component: () => import(/* webpackChunkName: "vista-create-new" */ './views/EditPlan.vue'),
      },
    ],
  },
];

export default routes;
